import { TASK_TYPE } from "../actions/constants";

const initialState = {
    list: [],
    details: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case TASK_TYPE.FIND:
            return {
                ...state,
                list: payload
            };

        case TASK_TYPE.ADD:
            let task_types_add = state.list;

            task_types_add = [
                ...task_types_add, payload
            ];

            task_types_add = task_types_add.sort(function (a, b) {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                list: task_types_add
            };

        case TASK_TYPE.DETAILS:
            return {
                ...state,
                details: payload
            };

        case TASK_TYPE.EDIT:
            let task_types_edit = state.list;

            task_types_edit = task_types_edit.map(type => type._id === payload._id ? payload : type);

            task_types_edit = task_types_edit.sort(function (a, b) {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                list: task_types_edit
            };

        case TASK_TYPE.DELETE:
            return {
                ...state,
                list: state.list.filter( type => type._id !== payload.id )
            };

        default:
            return state;
    }
}