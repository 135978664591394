import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { setAlert } from '../../actions/alert';
import { addTask } from '../../actions/tasks';
import { findProjects } from '../../actions/projects';
import { findTasksTypes } from '../../actions/tasks_types';
import { findUsers } from '../../actions/users';

import { CustomValueContainer } from '../../components/react_select/ReactSelect';

const AddTask = ({ task, restablishTask }) => {

    const dispatch =  useDispatch();

    const { list: list_projects } = useSelector(state => state.projects);
    const { list: list_tasks_types } = useSelector(state => state.tasks_types);
    const { list: list_users } = useSelector(state => state.users);

    const [addModal, setAddModal] = useState(false);
    const [statusProcess, setStatusProcess] = useState(false);

    const [taskData, setTaskData] = useState({
        project: null, 
        type: null, 
        user: null, 
        priority: null, 
        description: null
    });

    const [projectData, setProjectData] = useState(null);
    const [projectsFilter, setProjectsFilter] = useState([]);

    const [taskTypeData, setTaskTypeData] = useState(null);
    const [tasksTypesFilter, setTasksTypesFilter] = useState([]);

    const [userData, setUserData] = useState(null);
    const [usersFilter, setUsersFilter] = useState([]);

    const [priorityData, setPriorityData] = useState(null);
    const priotitiesFilter = [
        {value: 0, label: 'Low'},
        {value: 1, label: 'Medium'},
        {value: 2, label: 'High'}
    ];

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided, 
        }),
        control: (provided, state) => ({
            ...provided,
            paddingTop: '5px',
            paddingBottom: '5px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Add task */
    const handleOpen = () => {
        dispatch(findProjects());
        dispatch(findTasksTypes());
        dispatch(findUsers());
    }

    const handleSelectProject = (option) => {
        setProjectData(option);

        setTaskData({
            ...taskData,
            project: option != null ? option.value : null
        });
    }

    const handleSelectTaskType = (option) => {
        setTaskTypeData(option);

        setTaskData({
            ...taskData,
            type: option != null ? option.value : null
        });
    }

    const handleSelectUser = (option) => {
        setUserData(option);

        setTaskData({
            ...taskData,
            user: option != null ? option.value : null
        });
    }

    const handleSelectPriority = (option) => {
        setPriorityData(option);

        setTaskData({
            ...taskData,
            priority: option != null ? option.value : null
        });
    }
    
    const handleInputChange = ({ target }) => {
        setTaskData({
            ...taskData,
            [target.name]: target.value
        });
    }
    
    const handleProcess = async () => {
        if(taskData.user && taskData.type){
            await setStatusProcess(true);
            await dispatch(addTask(taskData));
            await handleClose();
            await setStatusProcess(false);
        }else{
            await dispatch(setAlert('Type and user are required', 'danger'));
        }
    }

    const handleClose = () => {
        if(!statusProcess){
            setTaskData({
                project: null, 
                type: null, 
                user: null, 
                priority: null, 
                description: null
            });

            setProjectData(null);
            setTaskTypeData(null);
            setUserData(null);
            setPriorityData(null);

            setAddModal(false);
            restablishTask(null);
        }
    }

    useEffect(() => {
        if(task){
            setAddModal(true);

            setTaskData({
                ...taskData
            });
        }        
    }, [task]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < list_projects?.length; i++) {
            options.push({ value: list_projects[i]?._id, label: list_projects[i]?.address });
        }

        setProjectsFilter(options);
    }, [list_projects]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < list_tasks_types?.length; i++) {
            options.push({ value: list_tasks_types[i]?._id, label: list_tasks_types[i]?.name });
        }

        setTasksTypesFilter(options);
    }, [list_tasks_types]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < list_users?.length; i++) {
            options.push({ value: list_users[i]?._id, label: [list_users[i]?.name, list_users[i]?.lastname].join(' ') });
        }

        setUsersFilter(options);
    }, [list_users]);

    return (
        <Modal show={addModal} size="xl" backdrop="static" centered onEntered={() => handleOpen()} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Add task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-8 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                id="project"
                                name="project"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Project"
                                options={projectsFilter}
                                value={projectData}
                                isClearable={true}
                                onChange={(value) => handleSelectProject(value)}
                                menuPortalTarget={document.body}
                                components={{ ValueContainer: CustomValueContainer }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                id="type"
                                name="type"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Job description"
                                options={tasksTypesFilter}
                                value={taskTypeData}
                                isClearable={true}
                                onChange={(value) => handleSelectTaskType(value)}
                                menuPortalTarget={document.body}
                                components={{ ValueContainer: CustomValueContainer }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                id="user"
                                name="user"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Worked by"
                                options={usersFilter}
                                value={userData}
                                isClearable={true}
                                onChange={(value) => handleSelectUser(value)}
                                menuPortalTarget={document.body}
                                components={{ ValueContainer: CustomValueContainer }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                id="priority"
                                name="priority"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Priority"
                                options={priotitiesFilter}
                                value={priorityData}
                                isClearable={true}
                                onChange={(value) => handleSelectPriority(value)}
                                menuPortalTarget={document.body}
                                components={{ ValueContainer: CustomValueContainer }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Description" id="description" name="description" value={taskData.description} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="description">Description</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleClose()} disabled={ statusProcess }><i className="fas fa-times fa-sm"></i> Cancel</button>
                {
                    statusProcess ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Save</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcess()}><i className="fas fa-check fa-sm"></i> Save</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default AddTask;