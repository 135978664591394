import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { editType, getType, clearType } from '../../actions/types';

const EditType = () => {

    let breadcrumbRoutes = [
        {
            activo: false,
            name: 'Types',
            path: '/types'
        },
        {
            activo: true,
            name: 'Edit type',
            path: '/types/edit/'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { id } = useParams();

    const { details } = useSelector(state => state.types);

    const [typeData, setTypeData] = useState({
        id: null, 
        name: null, 
        price: null,
        description: null
    });

    const [projects, setProjects] = useState({
        view: false,
        add: false,
        edit: false,
        details: false,
        delete: false
    });

    const [employees, setEmployees] = useState({
        view: false,
        add: false,
        edit: false,
        details: false,
        delete: false
    });

    const [types, setTypes] = useState({
        view: false,
        add: false,
        edit: false,
        details: false,
        delete: false
    });

    const [payroll, setPayroll] = useState({
        view: false,
        add: false,
        edit: false,
        details: false,
        delete: false
    });

    const [expenses, setExpenses] = useState({
        view: false,
        add: false,
        edit: false,
        details: false,
        delete: false
    });

    const [categories, setCategories] = useState({
        view: false,
        add: false,
        edit: false,
        details: false,
        delete: false
    });

    const [tasks, setTasks] = useState({
        view: false,
        add: false,
        edit: false,
        details: false,
        delete: false
    });

    const [tasks_types, setTasksTypes] = useState({
        view: false,
        add: false,
        edit: false,
        details: false,
        delete: false
    });

    const [configuration, setConfiguration] = useState({
        profile: {
            view: false,
            edit: false
        }, 
        company: {
            view: false,
            edit: false
        }
    });

    /* General functions */
    const updateNestedState = (state, keys, value) => {
        let newState = { ...state };
    
        const lastKey = keys.pop();
    
        let tempState = newState;

        keys.forEach(key => {
          tempState = tempState[key];
        });
    
        tempState[lastKey] = value;
        
        return newState;
    };

    /* Edit type */
    const handleInputChange = ({ target }) => {
        setTypeData({
            ...typeData,
            [target.name]: target.value
        });
    }

    const onChangeCheckbox = (e) => {
        const [type, ...sections] = e.target.dataset.type.split('-');

        switch(type){
            case 'projects':
                setProjects(prevState => updateNestedState(prevState, sections, e.target.checked));
                break;

            case 'employees':
                setEmployees(prevState => updateNestedState(prevState, sections, e.target.checked));
                break;

            case 'types':
                setTypes(prevState => updateNestedState(prevState, sections, e.target.checked));
                break;

            case 'payroll':
                setPayroll(prevState => updateNestedState(prevState, sections, e.target.checked));
                break;

            case 'expenses':
                setExpenses(prevState => updateNestedState(prevState, sections, e.target.checked));
                break;

            case 'categories':
                setCategories(prevState => updateNestedState(prevState, sections, e.target.checked));
                break;

            case 'tasks':
                setTasks(prevState => updateNestedState(prevState, sections, e.target.checked));
                break;

            case 'tasks_types':
                setTasksTypes(prevState => updateNestedState(prevState, sections, e.target.checked));
                break;

            case 'configuration':
                setConfiguration(prevState => updateNestedState(prevState, sections, e.target.checked));
                break;

            default:
                break;
        }
    }

    const handleSave = async () => {
        if(typeData.name){
            await dispatch(editType({
                ...typeData, 
                projects: projects,
                employees: employees,
                types: types,
                payroll: payroll,
                expenses: expenses,
                categories: categories,
                tasks: tasks,
                tasks_types: tasks_types,
                configuration: configuration
            }));

            await navigate("/types");
        }else{
            await dispatch(setAlert('All fields are required', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(clearType());

        return () => {
            dispatch(clearType());
        }
    }, []);

    useEffect(() => {
        dispatch(getType(id));

        if(id){
            breadcrumbRoutes[1].path = breadcrumbRoutes[1].path + id;

            dispatch(editBreadcrumb(breadcrumbRoutes));
        }
    }, [id]);

    useEffect(() => {
        if(details){
            setTypeData({
                ...typeData,
                id: details?._id,
                name: details?.name,
                description: details?.description
            });

            if(details?.projects){
                setProjects(details?.projects);
            }

            if(details?.employees){
                setEmployees(details?.employees);
            }

            if(details?.types){
                setTypes(details?.types);
            }

            if(details?.payroll){
                setPayroll(details?.payroll);
            }

            if(details?.expenses){
                setExpenses(details?.expenses);
            }

            if(details?.categories){
                setCategories(details?.categories);
            }

            if(details?.tasks){
                setTasks(details?.tasks);
            }

            if(details?.tasks_types){
                setTasksTypes(details?.tasks_types);
            }

            if(details?.configuration){
                setConfiguration(details?.configuration);
            }

        }
    }, [details]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);    

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 py-4">
                    <div className="row">
                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Name" id="name" name="name" value={typeData.name} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="name">Name</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-9 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <textarea className="form-control textareCampo" placeholder="Description" id="description" name="description" value={typeData.description} onChange={e => handleInputChange(e)}></textarea>
                                    <label htmlFor="description">Description</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <h5>Permissions</h5>

                        {/* Projects */}
                        <div className="col-md-3 mt-4">
                            <div className="card px-4 py-4">
                                <b>Projects</b>
                                <hr />
                                <div className="form-check">
                                    <input className="form-check-input projects" type="checkbox" checked={projects?.view} id="projects-view" data-type="projects-view" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="projects-view">
                                        View
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input projects" type="checkbox" checked={projects?.add} id="projects-add" data-type="projects-add" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="projects-add">
                                        Add
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input projects" type="checkbox" checked={projects?.edit} id="projects-edit" data-type="projects-edit" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="projects-edit">
                                        Edit
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input projects" type="checkbox" checked={projects?.details} id="projects-details" data-type="projects-details" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="projects-details">
                                        Details
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input projects" type="checkbox" checked={projects?.delete} id="projects-delete" data-type="projects-delete" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="projects-delete">
                                        Delete
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Employees */}
                        <div className="col-md-3 mt-4">
                            <div className="card px-4 py-4">
                                <b>Employees</b>
                                <hr />
                                <div className="form-check">
                                    <input className="form-check-input employees" type="checkbox" checked={employees?.view} id="employees-view" data-type="employees-view" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="employees-view">
                                        View
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input employees" type="checkbox" checked={employees?.add} id="employees-add" data-type="employees-add" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="employees-add">
                                        Add
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input employees" type="checkbox" checked={employees?.edit} id="employees-edit" data-type="employees-edit" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="employees-edit">
                                        Edit
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input employees" type="checkbox" checked={employees?.details} id="employees-details" data-type="employees-details" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="employees-details">
                                        Details
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input employees" type="checkbox" checked={employees?.delete} id="employees-delete" data-type="employees-delete" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="employees-delete">
                                        Delete
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Types of employees */}
                        <div className="col-md-3 mt-4">
                            <div className="card px-4 py-4">
                                <b>Types of employees</b>
                                <hr />
                                <div className="form-check">
                                    <input className="form-check-input types" type="checkbox" checked={types?.view} id="types-view" data-type="types-view" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="types-view">
                                        View
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input types" type="checkbox" checked={types?.add} id="types-add" data-type="types-add" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="types-add">
                                        Add
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input types" type="checkbox" checked={types?.edit} id="types-edit" data-type="types-edit" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="types-edit">
                                        Edit
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input types" type="checkbox" checked={types?.details} id="types-details" data-type="types-details" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="types-details">
                                        Details
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input types" type="checkbox" checked={types?.delete} id="types-delete" data-type="types-delete" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="types-delete">
                                        Delete
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Payroll */}
                        <div className="col-md-3 mt-4">
                            <div className="card px-4 py-4">
                                <b>Payroll</b>
                                <hr />
                                <div className="form-check">
                                    <input className="form-check-input payroll" type="checkbox" checked={payroll?.view} id="payroll-view" data-type="payroll-view" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="payroll-view">
                                        View
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input payroll" type="checkbox" checked={payroll?.add} id="payroll-add" data-type="payroll-add" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="payroll-add">
                                        Add
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input payroll" type="checkbox" checked={payroll?.edit} id="payroll-edit" data-type="payroll-edit" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="payroll-edit">
                                        Edit
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input payroll" type="checkbox" checked={payroll?.details} id="payroll-details" data-type="payroll-details" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="payroll-details">
                                        Details
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input payroll" type="checkbox" checked={payroll?.delete} id="payroll-delete" data-type="payroll-delete" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="payroll-delete">
                                        Delete
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Expenses */}
                        <div className="col-md-3 mt-4">
                            <div className="card px-4 py-4">
                                <b>Expenses</b>
                                <hr />
                                <div className="form-check">
                                    <input className="form-check-input expenses" type="checkbox" checked={expenses?.view} id="expenses-view" data-type="expenses-view" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="expenses-view">
                                        View
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input expenses" type="checkbox" checked={expenses?.add} id="expenses-add" data-type="expenses-add" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="expenses-add">
                                        Add
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input expenses" type="checkbox" checked={expenses?.edit} id="expenses-edit" data-type="expenses-edit" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="expenses-edit">
                                        Edit
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input expenses" type="checkbox" checked={expenses?.details} id="expenses-details" data-type="expenses-details" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="expenses-details">
                                        Details
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input expenses" type="checkbox" checked={expenses?.delete} id="expenses-delete" data-type="expenses-delete" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="expenses-delete">
                                        Delete
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Categories */}
                        <div className="col-md-3 mt-4">
                            <div className="card px-4 py-4">
                                <b>Categories</b>
                                <hr />
                                <div className="form-check">
                                    <input className="form-check-input categories" type="checkbox" checked={categories?.view} id="categories-view" data-type="categories-view" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="categories-view">
                                        View
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input categories" type="checkbox" checked={categories?.add} id="categories-add" data-type="categories-add" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="categories-add">
                                        Add
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input categories" type="checkbox" checked={categories?.edit} id="categories-edit" data-type="categories-edit" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="categories-edit">
                                        Edit
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input categories" type="checkbox" checked={categories?.details} id="categories-details" data-type="categories-details" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="categories-details">
                                        Details
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input categories" type="checkbox" checked={categories?.delete} id="categories-delete" data-type="categories-delete" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="categories-delete">
                                        Delete
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Tasks */}
                        <div className="col-md-3 mt-4">
                            <div className="card px-4 py-4">
                                <b>Tasks</b>
                                <hr />
                                <div className="form-check">
                                    <input className="form-check-input tasks" type="checkbox" checked={tasks?.view} id="tasks-view" data-type="tasks-view" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="tasks-view">
                                        View
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input tasks" type="checkbox" checked={tasks?.add} id="tasks-add" data-type="tasks-add" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="tasks-add">
                                        Add
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input tasks" type="checkbox" checked={tasks?.edit} id="tasks-edit" data-type="tasks-edit" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="tasks-edit">
                                        Edit
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input tasks" type="checkbox" checked={tasks?.details} id="tasks-details" data-type="tasks-details" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="tasks-details">
                                        Details
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input tasks" type="checkbox" checked={tasks?.delete} id="tasks-delete" data-type="tasks-delete" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="tasks-delete">
                                        Delete
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Types of tasks */}
                        <div className="col-md-3 mt-4">
                            <div className="card px-4 py-4">
                                <b>Types of tasks</b>
                                <hr />
                                <div className="form-check">
                                    <input className="form-check-input tasks_types" type="checkbox" checked={tasks_types?.view} id="tasks_types-view" data-type="tasks_types-view" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="tasks_types-view">
                                        View
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input tasks_types" type="checkbox" checked={tasks_types?.add} id="tasks_types-add" data-type="tasks_types-add" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="tasks_types-add">
                                        Add
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input tasks_types" type="checkbox" checked={tasks_types?.edit} id="tasks_types-edit" data-type="tasks_types-edit" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="tasks_types-edit">
                                        Edit
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input tasks_types" type="checkbox" checked={tasks_types?.details} id="tasks_types-details" data-type="tasks_types-details" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="tasks_types-details">
                                        Details
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input tasks_types" type="checkbox" checked={tasks_types?.delete} id="tasks_types-delete" data-type="tasks_types-delete" onChange={onChangeCheckbox}></input>
                                    <label className="form-check-label" htmlFor="tasks_types-delete">
                                        Delete
                                    </label>
                                </div>
                            </div>
                        </div>

                        {/* Configuration */}
                        <div className="col-md-3 mt-4">
                            <div className="card px-4 py-4">
                                <b>Configuration</b>
                                <hr />
                                
                                <span className="title-section">Profile</span>
                                <div className="ps-3">
                                    <div className="form-check">
                                        <input className="form-check-input profile" type="checkbox" checked={configuration?.profile?.view} id="configuration-profile-view" data-type="configuration-profile-view" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration-profile-view">
                                            View
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input profile" type="checkbox" checked={configuration?.profile?.edit} id="configuration-profile-edit" data-type="configuration-profile-edit" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration-profile-edit">
                                            Edit
                                        </label>
                                    </div>
                                </div>

                                <span className="title-section">Company</span>
                                <div className="ps-3">
                                    <div className="form-check">
                                        <input className="form-check-input company" type="checkbox" checked={configuration?.company?.view} id="configuration-company-view" data-type="configuration-company-view" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration-company-view">
                                            View
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input company" type="checkbox" checked={configuration?.company?.edit} id="configuration-company-edit" data-type="configuration-company-edit" onChange={onChangeCheckbox}></input>
                                        <label className="form-check-label" htmlFor="configuration-company-edit">
                                            Edit
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleSave}>Save</button>
                            <Link to='/types'><button className="btn btn-danger float-end me-2">Cancel</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EditType;