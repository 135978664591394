import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';

import { formatTime, formatNumber, sortTableDate } from '../../utils/generalFunctions';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { filterTasks, findTasks } from '../../actions/tasks_reports';

import FilterTasks from './FilterTasks';

const Tasks = () => {

    let breadcrumbRoutes = [
        {
            activo: true,
            name: 'Reports',
            path: '/task_reports'
        }
    ];

    const dispatch =  useDispatch();

    const { list, details } = useSelector(state => state.tasks_reports);

    const [tasks, setTasks] = useState([]);

    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const columns = [
        {
            name: 'Worked by',
            sortable: true, 
            cell: item => item.user,
            wrap: false
        },
        {
            name: 'Project',
            sortable: true,
            cell: item => item.project,
            wrap: false,
            grow: 2
        },
        {
            name: 'Job description',
            sortable: true, 
            cell: row => row.type
        },
        {
            name: 'Description',
            sortable: true,
            cell: item => `${item.description || 'Not defined'}`,
            wrap: false,
            grow: 2
        },
        {
            name: 'Total time',
            sortable: true, 
            cell: item => formatTime(item.total_time), 
            wrap: false
        },
        {
            name: 'Created', 
            sortable: true, 
            sortFunction: sortTableDate, 
            cell: item => item.created ? new Date(item.created).toLocaleDateString('en-EU', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(item.created).toLocaleTimeString('en-EU', { hour: '2-digit', minute: '2-digit' }) : 'Not defined'
        },
        {
            name: 'Finished', 
            sortable: true, 
            sortFunction: sortTableDate, 
            cell: item => item.finished ? new Date(item.finished).toLocaleDateString('en-EU', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(item.finished).toLocaleTimeString('en-EU', { hour: '2-digit', minute: '2-digit' }) : 'Not defined'
        },
        {
            name: 'Status',
            cell: row => (
                <div className="text-right w-100">
                    { generateStatusTask(row.status) }
                </div>
            )
        }
    ];

    const generateStatusTask = (status) => {
        switch(status){
            case 0:
                return  <h6><Badge bg="secondary">Not started</Badge></h6>;
                break;

            case 1:
                return  <h6><Badge bg="primary">In progress</Badge></h6>;
                break;

            case 2:
                return <h6><Badge bg="success">Completed</Badge></h6>;
                break;

            case 3:
                return <h6><Badge bg="warning">On hold</Badge></h6>;
                break;

            default:
                break;
        }
    }

    /* Filters */
    const handleFilters = () => {
        dispatch(filterTasks(true));
    }

    useEffect(() => {
        dispatch(findTasks({
            report: null,
            group: null,
            time: null,
            user: null,
            project: null,
            type: null,
            start: null,
            end: null, 
            status: null
        }));
    }, []);

    useEffect(() => {
        setTasks(list);
    }, [list]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>

            <FilterTasks />

            <div className="row dashboard">
                <div className="col-md-12 py-4">

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary mx-1" onClick={() => handleFilters()}>Filters</button>
                    </div>

                    {
                        details ?
                            <>
                                <div className="row my-4">
                                    <div className="col-md-8 col-12">
                                        <div className="card info-card sales-card">
                                            <div className="card-body py-1">
                                                <h5 className="card-title">Time worked <span></span></h5>

                                                <div className="d-flex align-items-center">
                                                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                        <i className="bi bi-clock"></i>
                                                    </div>
                                                    <div className="ps-3">
                                                        <h6>{details ? formatTime(details.total_time) : ''}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-12">
                                        <div className="card info-card revenue-card">
                                            <div className="card-body py-1">
                                                <h5 className="card-title">Salary <span></span></h5>

                                                <div className="d-flex align-items-center">
                                                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <i className="bi bi-currency-dollar"></i>
                                                    </div>
                                                    <div className="ps-3">
                                                        <h6>{details ? formatNumber(details.total_salary, 2, '$ ', '') : ''}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-12">
                                        <div className="card info-card customers-card">
                                            <div className="card-body py-1">
                                                <h5 className="card-title">Filter information <span></span></h5>

                                                <div className="d-flex align-items-center">
                                                    <div className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                                    <i className="bi bi-filter"></i>
                                                    </div>
                                                    <div className="ps-3">
                                                        <span className="text-danger small pt-1 mx-3 fw-bold">Report type: <span className="text-muted small pt-2 ps-1">{details?.report || 'None'}</span></span> 
                                                        <span className="text-danger small pt-1 mx-3 fw-bold">Group by: <span className="text-muted small pt-2 ps-1">{details?.group || 'None'}</span></span> 
                                                        <span className="text-danger small pt-1 mx-3 fw-bold">Worker selected: <span className="text-muted small pt-2 ps-1">{details?.worker || 'None'}</span></span> 
                                                        <span className="text-danger small pt-1 mx-3 fw-bold">Project selected: <span className="text-muted small pt-2 ps-1">{details?.project || 'None'}</span></span>
                                                        <span className="text-danger small pt-1 mx-3 fw-bold">Job selected: <span className="text-muted small pt-2 ps-1">{details?.job || 'None'}</span></span>
                                                        <span className="text-danger small pt-1 mx-3 fw-bold">Period selected: <span className="text-muted small pt-2 ps-1">{details?.period || 'None'}</span></span>
                                                        <span className="text-danger small pt-1 mx-3 fw-bold">Status selected: <span className="text-muted small pt-2 ps-1">{details?.status || 'None'}</span></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>

                                <DataTable 
                                    columns={columns}
                                    data={tasks}
                                    pagination
                                    paginationPerPage={20}
                                    paginationRowsPerPageOptions={[5, 10, 20, 50]}
                                    paginationComponentOptions={paginationOptions}
                                    highlightOnHover={true}
                                />
                            </>
                        :
                            <section className="mt-5 error-404 d-flex flex-column align-items-center justify-content-center">
                                <h1>Generate report</h1>
                                <h2>Select the type and filters for your report.</h2>
                            </section>
                    }
                </div>
            </div>
        </Fragment>
    )
}

export default Tasks;