import React, { Fragment, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Components
import Notification from './components/layout/Notification';
import Breadcrumb from './components/layout/Breadcrumb';
import Layout from './components/layout/Layout';
import PrivateRoute from './components/routing/PrivateRoute';
import ValidateSession from './components/routing/ValidateSession';
import NoMatch from './components/routing/NoMatch';

// Login
import Login from './views/auth/Login';

// Dashboard
import Dashboard from './views/dashboard/Construction';

// Projects
import Projects from './views/projects/Projects';
import AddProject from './views/projects/AddProject';
import EditProject from './views/projects/EditProject';

// Employees
import Employees from './views/employees/Employees';
import AddEmployee from './views/employees/AddEmployee';
import EditEmployee from './views/employees/EditEmployee';

// Types
import Types from './views/types/Types';
import AddType from './views/types/AddType';
import EditType from './views/types/EditType';

// Payroll
import Payroll from './views/payroll/Construction';

// Expenses
import Expenses from './views/expenses/Expenses';
import AddExpense from './views/expenses/AddExpense';
import EditExpense from './views/expenses/EditExpense';

// Categories
import Categories from './views/categories/Categories';
import AddCategory from './views/categories/AddCategory';
import EditCategory from './views/categories/EditCategory';

// Tasks
import Tasks from './views/tasks/Tasks';

// TasksTypes
import TasksTypes from './views/tasks_types/TasksTypes';

// TasksReports
import TasksReports from './views/tasks_reports/TasksReports';

// Configuración
import Profile from './views/configuration/profile/Profile';

// Redux
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

function App() {
	if (localStorage.token) {
		setAuthToken(localStorage.token);
	}

	useEffect(() => {
		store.dispatch(loadUser());
	}, []);

	return (
		<Provider store={store}>
			<BrowserRouter>
				<Fragment>
					<Layout>								
						<Routes>
							{/* Login */}
							<Route exact path="/login" element={<ValidateSession><Login /></ValidateSession>}/>

							{/* Home */}
							<Route path="/" element={<PrivateRoute routePermission=''><Dashboard /></PrivateRoute>}/>
							<Route path="/home" element={<PrivateRoute routePermission=''><Dashboard /></PrivateRoute>}/>

							{/* Projects */}
							<Route path="/projects" element={<PrivateRoute routePermission='projects.view'><Projects /></PrivateRoute>}/>
							<Route path="/projects/add" element={<PrivateRoute routePermission='projects.add'><AddProject /></PrivateRoute>}/>
							<Route path="/projects/edit/:id" element={<PrivateRoute routePermission='projects.edit'><EditProject /></PrivateRoute>}/>

							{/* Employees */}
							<Route path="/employees" element={<PrivateRoute routePermission='employees.view'><Employees /></PrivateRoute>}/>
							<Route path="/employees/add" element={<PrivateRoute routePermission='employees.add'><AddEmployee /></PrivateRoute>}/>
							<Route path="/employees/edit/:id" element={<PrivateRoute routePermission='employees.edit'><EditEmployee /></PrivateRoute>}/>

							{/* Types */}
							<Route path="/types" element={<PrivateRoute routePermission='types.view'><Types /></PrivateRoute>}/>
							<Route path="/types/add" element={<PrivateRoute routePermission='types.add'><AddType /></PrivateRoute>}/>
							<Route path="/types/edit/:id" element={<PrivateRoute routePermission='types.edit'><EditType /></PrivateRoute>}/>

							{/* Payroll */}
							<Route path="/payroll" element={<PrivateRoute routePermission='payroll.view'><Payroll /></PrivateRoute>}/>

							{/* Expenses */}
							<Route path="/expenses" element={<PrivateRoute routePermission='expenses.view'><Expenses /></PrivateRoute>}/>
							<Route path="/expenses/add" element={<PrivateRoute routePermission='expenses.add'><AddExpense /></PrivateRoute>}/>
							<Route path="/expenses/edit/:id" element={<PrivateRoute routePermission='expenses.edit'><EditExpense /></PrivateRoute>}/>

							{/* Categories */}
							<Route path="/categories" element={<PrivateRoute routePermission='categories.view'><Categories /></PrivateRoute>}/>
							<Route path="/categories/add" element={<PrivateRoute routePermission='categories.add'><AddCategory /></PrivateRoute>}/>
							<Route path="/categories/edit/:id" element={<PrivateRoute routePermission='categories.edit'><EditCategory /></PrivateRoute>}/>

							{/* Tasks */}
							<Route path="/tasks" element={<PrivateRoute routePermission='tasks.view'><Tasks /></PrivateRoute>}/>

							{/* Task´s Types */}
							<Route path="/task_types" element={<PrivateRoute routePermission='tasks_types.view'><TasksTypes /></PrivateRoute>}/>

							{/* Task´s Reports */}
							<Route path="/task_reports" element={<PrivateRoute routePermission='tasks.view'><TasksReports /></PrivateRoute>}/>

							{/* Configuration */}
							<Route path="/configuration" element={<PrivateRoute routePermission='configuration.profile.view'><Profile /></PrivateRoute>}/>
							
							{/* Not found anything*/}
							<Route path="*" element={<NoMatch />}/>
						</Routes>
					</Layout>
				</Fragment>
			</BrowserRouter>
		</Provider>
	);
}

export default App;