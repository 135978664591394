import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { setAlert } from '../../actions/alert';
import { clearTaskType, getTaskType, editTaskType } from '../../actions/tasks_types';

const EditTaskType = ({ task_type, restablishTaskType }) => {

    const dispatch =  useDispatch();

    const { details } = useSelector(state => state.tasks_types);

    const [editModal, setEditModal] = useState(false);
    const [statusProcess, setStatusProcess] = useState(false);

    const [taskTypeData, setTaskTypeData] = useState({
        id: null, 
        name: null
    });

    /* Edit task type */
    const handleOpen = () => {
        dispatch(getTaskType(task_type?._id));
    }

    const handleInputChange = ({ target }) => {
        setTaskTypeData({
            ...taskTypeData,
            [target.name]: target.value
        });
    }
    
    const handleProcess = async () => {
        if(taskTypeData.name){
            await setStatusProcess(true);
            await dispatch(editTaskType(taskTypeData));
            await handleClose();
            await setStatusProcess(false);
        }else{
            await dispatch(setAlert('Name is required', 'danger'));
        }
    }

    const handleClose = () => {
        if(!statusProcess){
            setTaskTypeData({
                id: null, 
                name: null
            });

            setEditModal(false);

            restablishTaskType(null);
        }
    }

    useEffect(() => {
        dispatch(clearTaskType());
    }, []);

    useEffect(() => {
        if(task_type){
            setEditModal(true);
        }        
    }, [task_type]);

    useEffect(() => {
        if(details){
            setTaskTypeData({
                ...taskTypeData,
                id: details?._id, 
                name: details?.name
            });
        }
    }, [details]);

    return (
        <Modal show={editModal} size="md" backdrop="static" centered onEntered={() => handleOpen()} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Edit type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12 form-group mb-2">
                        <div className="input-group">
                            <div className="form-floating">
                                <input type="text" className="form-control" placeholder="Name" id="name" name="name" value={taskTypeData.name} onChange={e => handleInputChange(e)}/>
                                <label htmlFor="name">Name</label>
                            </div>
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleClose()} disabled={ statusProcess }><i className="fas fa-times fa-sm"></i> Cancel</button>
                {
                    statusProcess ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Save</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcess()}><i className="fas fa-check fa-sm"></i> Save</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default EditTaskType;