import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { addProject } from '../../actions/projects';

import { CustomValueContainer } from '../../components/react_select/ReactSelect';

const AddProject = () => {

    let breadcrumbRoutes = [
        {
            activo: false,
            name: 'Projects',
            path: '/projects'
        }, 
        {
            activo: true,
            name: 'Add project',
            path: '/projects/add'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [projectData, setProjectData] = useState({
        address: null, 
        apn: null, 
        project_type: null,
        property_type: null
    });

    const [projectTypeData, setProjectTypeData] = useState(null);
    const [propertyTypeData, setPropertyTypeData] = useState(null);

    const projectTypesFilter = [
        {
            label: 'Purchase',
            options: [
                { value: 0, label: 'Assigned' },
                { value: 1, label: 'Flipping' },
                { value: 2, label: 'Rent to hold' },
                { value: 3, label: 'New development' },
                { value: 4, label: 'To do entitlement' }
            ]
        },
        {
            label: 'Construction',
            options: [
                { value: 5, label: 'New' },
                { value: 6, label: 'Rehab' },
                { value: 7, label: 'Entitlement' },
                { value: 8, label: 'Other' }
            ]
        }
    ];

    const propertyTypesFilter = [
        { value: 0, label: 'Single family (1)' },
        { value: 1, label: 'Single family (2-4)' },
        { value: 2, label: 'Multiple family' },
        { value: 3, label: 'Mix use' },
        { value: 4, label: 'Studing housing' },
        { value: 5, label: 'Apartment units' },
        { value: 6, label: 'Condominuims' },
        { value: 7, label: 'Vacant land' }
    ];

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided, 
        }),
        control: (provided, state) => ({
            ...provided,
            paddingTop: '5px',
            paddingBottom: '5px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Add project */
    const handleInputChange = ({ target }) => {
        setProjectData({
            ...projectData,
            [target.name]: target.value
        });
    }

    const handleSelectProjectType = (option) => {
        setProjectTypeData(option);

        setProjectData({
            ...projectData,
            project_type: option != null ? option.value : null
        });
    }

    const handleSelectPropertyType = (option) => {
        setPropertyTypeData(option);

        setProjectData({
            ...projectData,
            property_type: option != null ? option.value : null
        });
    }

    const handleSave = async () => {
        if(projectData.address && projectData.project_type != null && projectData.property_type != null){
            await dispatch(addProject(projectData));

            await navigate('/projects');
        }else{
            await dispatch(setAlert('All fields are required', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 py-4">
                    <div className="row">
                        <div className="col-md-9 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Address" id="address" name="address" value={projectData.address} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="address">Address</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="APN" id="apn" name="apn" value={projectData.apn} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="apn">APN</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    id="type"
                                    name="type"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Project type"
                                    options={projectTypesFilter}
                                    value={projectTypeData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectProjectType(value)}
                                    menuPortalTarget={document.body}
                                    components={{ ValueContainer: CustomValueContainer }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    id="type"
                                    name="type"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Property type"
                                    options={propertyTypesFilter}
                                    value={propertyTypeData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectPropertyType(value)}
                                    menuPortalTarget={document.body}
                                    components={{ ValueContainer: CustomValueContainer }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleSave}>Save</button>
                            <Link to="/projects"><button className="btn btn-danger float-end me-2">Cancel</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AddProject;