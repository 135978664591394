export const formatNumber = (number, decimales, prefix, sufix) => {
    let numberFormated = (Math.round(number * 100) / 100).toFixed(decimales);
    const decimalsFormated = numberFormated.toLocaleString(undefined, { maximumFractionDigits: decimales });
    const finalFormated = prefix + String(decimalsFormated).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + sufix;
    return finalFormated;    
}

export const formatTime = (seconds) => {
    let message = 'Without time';

    if(seconds > 0){
        let minutes_quantity = 1000 * 60;

        let minutes = Math.floor(seconds / minutes_quantity);
        let hours = 0;
        let days = 0;
        let weeks = 0;

        if(minutes >= 2400){
            weeks = Math.floor(minutes / 2400);
            minutes = minutes - (weeks * 2400);
        }

        if(minutes >= 480){
            days = Math.floor(minutes / 480);
            minutes = minutes - (days * 480);
        }

        if(minutes >= 60){
            hours = Math.floor(minutes / 60);
            minutes = minutes - (hours * 60);
        }

        if(minutes || hours || days || weeks){
            let text_minutes = minutes > 0 ? minutes + ' ' + (minutes == 1 ? 'minute' : 'minutes') : '';
            let text_hours = hours > 0 ? hours + ' ' + (hours == 1 ? 'hour' : 'hours') : '';
            let text_days = days > 0 ? days + ' ' + (days == 1 ? 'day' : 'days') : '';
            let text_weeks = weeks > 0 ? weeks + ' ' + (weeks == 1 ? 'week' : 'weeks') : '';

            message = text_weeks + ' ' + text_days + ' ' + text_hours + ' ' + text_minutes;
        }else{
            message = 'Less than minute';
        }
    }

    return message;
}

export const sortTableNumber = (primera, segunda, campo) => {
    if(!isNaN(primera[campo])){
        let a = Number(primera[campo]);
        let b = Number(segunda[campo]);
        
        if (a > b) {
            return 1;
        }

        if (b > a) {
            return -1;
        }

        return 0;
    }else{
        return 0;
    }
}

export const sortTableString = (primera, segunda) => {
    let a = String(primera.canjeado);
    let b = String(segunda.canjeado);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;
}

export const sortTableDate = (first, second) => {
    let a = new Date(first.created);
    let b = new Date(second.created);
    
    if (a > b) {
        return 1;
    }

    if (b > a) {
        return -1;
    }

    return 0;    
}

export const getLuminance = (color) => {
    if (color[0] === '#') {
        let r = parseInt(color.substring(1, 3), 16);
        let g = parseInt(color.substring(3, 5), 16);
        let b = parseInt(color.substring(5, 7), 16);
        
        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    }
}

export const getTextColor = (backgroundColor) => {
    const luminance = getLuminance(backgroundColor);

    return luminance > 128 ? '#000000' : '#ffffff';
}