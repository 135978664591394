import axios from 'axios';

import { PROJECT } from './constants';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Projects */
export const findProjects = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/projects/view`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROJECT.FIND,
            payload: res.data.projects
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const addProject = (project) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/add`, project, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const getProject = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/get`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROJECT.DETAILS,
            payload: res.data.project
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const clearProject = () => dispatch => {
    dispatch({
        type: PROJECT.DETAILS,
        payload: null
    });
}

export const editProject = (project) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/edit`, project, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const deleteProject = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/projects/delete`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: PROJECT.DELETE,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}