import axios from 'axios';

import { TASK_REPORT } from './constants';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Tasks */
export const filterTasks = (option) => dispatch => {
    dispatch({
        type: TASK_REPORT.FILTERS,
        payload: option
    });
}

export const findTasks = (filters) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/tasks_reports/view`, filters, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: TASK_REPORT.FIND,
            payload: res.data
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}