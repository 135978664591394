import { EXPENSE } from "../actions/constants";

const initialState = {
    list: [],
    details: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case EXPENSE.FIND:
            return {
                ...state,
                list: payload
            };

        case EXPENSE.DETAILS:
            return {
                ...state,
                details: payload
            };

        case EXPENSE.DELETE:
            return {
                ...state,
                list: state.list.filter( expense => expense._id !== payload.id )
            };

        default:
            return state;
    }
}