import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'react-bootstrap';

import { formatTime, sortTableDate } from '../../utils/generalFunctions';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { filterTasks, findTasks, statusTask } from '../../actions/tasks';

import FilterTasks from './FilterTasks';
import AddTask from './AddTask';
import EditTask from './EditTask';
import DeleteTask from './DeleteTask';

const Tasks = () => {

    let breadcrumbRoutes = [
        {
            activo: true,
            name: 'Tasks',
            path: '/tasks'
        }
    ];

    const dispatch =  useDispatch();

    const { list } = useSelector(state => state.tasks);

    const [tasks, setTasks] = useState([]);

    const [taskAdd, setTaskAdd] = useState(null);
    const [taskEdit, setTaskEdit] = useState(null);
    const [taskDelete, setTaskDelete] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const columns = [
        {
            name: 'Worked by',
            sortable: true, 
            cell: item => `${item.user ? [item.user.name, item.user.lastname].join(' ') : 'Not defined'}`,
            wrap: false
        },
        {
            name: 'Project',
            sortable: true,
            cell: item => `${item.project?.address || 'Not defined'}`,
            wrap: false,
            grow: 2
        },
        {
            name: 'Job description',
            sortable: true, 
            cell: row => row.type?.name || 'Not defined'
        },
        {
            name: 'Description',
            sortable: true,
            cell: item => `${item.description || 'Not defined'}`,
            wrap: false,
            grow: 2
        },
        {
            name: 'Total time',
            sortable: true, 
            cell: item => formatTime(item.total_time), 
            wrap: false
        },
        {
            name: 'Created', 
            sortable: true, 
            sortFunction: sortTableDate, 
            cell: item => item.created ? new Date(item.created).toLocaleDateString('en-EU', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(item.created).toLocaleTimeString('en-EU', { hour: '2-digit', minute: '2-digit' }) : 'Not defined'
        },
        {
            name: 'Finished', 
            sortable: true, 
            sortFunction: sortTableDate, 
            cell: item => item.finished ? new Date(item.finished).toLocaleDateString('en-EU', { year: 'numeric', month: 'long', day: 'numeric' }) + ' ' + new Date(item.finished).toLocaleTimeString('en-EU', { hour: '2-digit', minute: '2-digit' }) : 'Not defined'
        },
        {
            name: 'Status',
            cell: row => (
                <div className="text-right w-100">
                    { generateStatusTask(row.status) }
                </div>
            )
        },
        {
            name: '',
            cell: row => (
                <div className="text-right w-100">
                    <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDelete(row)}><i className="bi bi-x-lg text-danger"></i></button>
                    {
                        row.status == 0 && 
                            (
                                <>
                                    <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEdit(row)}><i className="bi bi-pencil-fill text-primary"></i></button>
                                    <button type="button" className="btn btn-md btn-link px-2 float-end" onClick={() => handleStatus(row)}><i className={'bi bi-' + (row.status == 1 ? 'pause-fill' : 'play-fill') + ' text-'+ (row.status == 1 ? 'warning' : 'success')}/></button>
                                </>
                            )
                        
                    }
                    
                    {
                        (row.status == 1 || row.status == 3) && 
                            (
                                <>
                                    <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEdit(row)}><i className="bi bi-pencil-fill text-primary"></i></button>
                                    <button type="button" className="btn btn-md btn-link px-2 float-end" onClick={() => handleFinish(row)}><i className="bi bi-stop-fill text-danger"/></button>
                                    <button type="button" className="btn btn-md btn-link px-2 float-end" onClick={() => handleStatus(row)}><i className={'bi bi-' + (row.status == 1 ? 'pause-fill' : 'play-fill') + ' text-'+ (row.status == 1 ? 'warning' : 'success')}/></button>
                                </>
                            )
                    }   
                </div>
            )
        }
    ];

    const generateStatusTask = (status) => {
        switch(status){
            case 0:
                return  <h6><Badge bg="secondary">Not started</Badge></h6>;
                break;

            case 1:
                return  <h6><Badge bg="primary">In progress</Badge></h6>;
                break;

            case 2:
                return <h6><Badge bg="success">Completed</Badge></h6>;
                break;

            case 3:
                return <h6><Badge bg="warning">On hold</Badge></h6>;
                break;

            default:
                break;
        }
    }

    /* Add */
    const handleAdd = (task) => {
        setTaskEdit(null);
        setTaskDelete(null);

        setTaskAdd(task);
    }

    /* Edit */
    const handleEdit = (task) => {
        setTaskAdd(null);
        setTaskDelete(null);

        setTaskEdit(task);
    }

    /* Status */
    const handleStatus = (task) => {
        let status = task.status == 0 || task.status == 3 ? 1 : 3;

        dispatch(statusTask({
            ...task, 
            status
        }));
    }

    /* Finish */
    const handleFinish = (task) => {
        dispatch(statusTask({
            ...task, 
            status: 2
        }));
    }

    /* Delete */
    const handleDelete = (task) => {
        setTaskAdd(null);
        setTaskEdit(null);

        setTaskDelete(task);
    }

    /* Filters */
    const handleFilters = () => {
        dispatch(filterTasks(true));
    }

    useEffect(() => {
        dispatch(findTasks({
            user: null,
            project: null,
            type: null,
            start: null,
            end: null, 
            status: null
        }));
    }, []);

    useEffect(() => {
        setTasks(list);
    }, [list]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>

            <FilterTasks />
            <AddTask task={taskAdd} restablishTask={handleAdd} />
            <EditTask task={taskEdit} restablishTask={handleEdit} />
            <DeleteTask task={taskDelete} restablishTask={handleDelete} />

            <div className="row">
                <div className="col-md-12 py-4">

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-secondary mx-1" onClick={() => handleFilters()}>Filters</button>
                        <button type="button" className="btn btn-primary" onClick={() => handleAdd({ })}>Add task</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={tasks}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Tasks;