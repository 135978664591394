import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { findTasks, filterTasks } from '../../actions/tasks';
import { findUsers } from '../../actions/users';
import { findTasksTypes } from '../../actions/tasks_types';
import { findProjects } from '../../actions/projects';

import { CustomValueContainer } from '../../components/react_select/ReactSelect';

const FiltrosProducto = () => {

    const dispatch =  useDispatch();

    const { filter_window } = useSelector(state => state.tasks);
    const { list: list_users } = useSelector(state => state.users);
    const { list: list_types } = useSelector(state => state.tasks_types);
    const { list: list_projects } = useSelector(state => state.projects);

    const [statusProcess, setStatusProcess] = useState(false);

    const [filtersData, setFiltersData] = useState({
        user: null,
        project: null,
        type: null,
        start: null,
        end: null, 
        status: null
    });

    const [userData, setUserData] = useState(null);
    const [usersFilter, setUsersFilter] = useState([]);

    const [projectData, setProjectData] = useState(null);
    const [projectsFilter, setProjectsFilter] = useState([]);

    const [typeData, setTypeData] = useState(null);
    const [typesFilter, setTypesFilter] = useState([]);

    const [statusData, setStatusData] = useState(null);
    const statusFilter = [
        { value: 0, label: 'Not started' }, 
        { value: 1, label: 'In progress' }, 
        { value: 2, label: 'Completed' }, 
        { value: 3, label: 'On hold' }
    ];

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "15px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Filtros */
    const handleOpen = () => {
        dispatch(findUsers());
        dispatch(findTasksTypes());
        dispatch(findProjects());
    }

    const handleSelectUser = (option) => {
        setFiltersData({
            ...filtersData,
            user: option ? option.value : null
        });

        setUserData(option);
    }

    const handleSelectProject = (option) => {
        setFiltersData({
            ...filtersData,
            project: option ? option.value : null
        });

        setProjectData(option);
    }

    const handleSelectType = (option) => {
        setFiltersData({
            ...filtersData,
            type: option ? option.value : null
        });

        setTypeData(option);
    }

    const handleDatePicker = (name, value) => {
        setFiltersData({
            ...filtersData,
            [name]: value
        });
    }

    const handleSelectStatus = (option) => {
        setFiltersData({
            ...filtersData,
            status: option ? option.value : null
        });

        setStatusData(option);
    }
    
    const handleProcesar = async (clean) => {
        await setStatusProcess(true);

        if(!clean){
            await dispatch(findTasks(filtersData));
        }else{
            let base_information = {
                ...filtersData, 
                user: null,
                project: null,
                type: null,
                start: null,
                end: null, 
                status: null
            };

            await dispatch(findTasks(base_information));

            await setFiltersData(base_information);

            await setUserData(null);
            await setProjectData(null);
            await setTypeData(null);
            await setStatusData(null);
        }

        await handleClose();
        await setStatusProcess(false);
    }

    const handleClose = () => {
        if(!statusProcess){
            dispatch(filterTasks(false));
        }
    }

    useEffect(() => {
        let options = [];

        for (var i = 0; i < list_users?.length; i++) {
            options.push({ value: list_users[i]._id, label: [list_users[i].name, list_users[i].lastname].join(' ') });
        }

        setUsersFilter(options);
    }, [list_users]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < list_projects.length; i++) {
            options.push({ value: list_projects[i]._id, label: list_projects[i].address });
        }

        setProjectsFilter(options);
    }, [list_projects]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < list_types.length; i++) {
            options.push({ value: list_types[i]._id, label: list_types[i].name });
        }

        setTypesFilter(options);
    }, [list_types]);

    return (
        <Modal show={filter_window} size="xl" backdrop="static" centered onEntered={() => handleOpen()} onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Filter tasks</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-3 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                name="user"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Worked by"
                                options={usersFilter}
                                value={userData}
                                isClearable={true}
                                onChange={(value) => handleSelectUser(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-6 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                name="project"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Project"
                                options={projectsFilter}
                                value={projectData}
                                isClearable={true}
                                onChange={(value) => handleSelectProject(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-3 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                name="type"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Job description"
                                options={typesFilter}
                                value={typeData}
                                isClearable={true}
                                onChange={(value) => handleSelectType(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="start"
                                    name="start"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ filtersData.start != null ? new Date(filtersData.start) : '' }
                                    onChange={ date => handleDatePicker('start', date) }
                                />
                                <label className="label-datepicker" htmlFor="start">Create date</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group">
                            <div className="form-floating">
                                <DatePicker
                                    id="end"
                                    name="end"
                                    className="form-control text-center input-datepícker"
                                    dateFormat="dd/MM/yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                    selected={ filtersData.end != null ? new Date(filtersData.end) : '' }
                                    onChange={ date => handleDatePicker('end', date) }
                                />
                                <label className="label-datepicker" htmlFor="end">Finish date</label>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 form-group mb-3">
                        <div className="input-group h-100">
                            <Select
                                name="status"
                                className="select-group"
                                classNamePrefix="react-select"
                                placeholder="Status"
                                options={statusFilter}
                                value={statusData}
                                isClearable={true}
                                onChange={(value) => handleSelectStatus(value)}
                                menuPortalTarget={document.body}
                                menuPlacement="auto"
                                components={{
                                    ValueContainer: CustomValueContainer
                                }}
                                styles={{ ...styles_select }}
                            />
                        </div>
                    </div>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                {
                    statusProcess ?
                        <>
                            <button type="button" className="btn btn-secondary" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Clear filters</button>
                            <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Search</button>
                        </>
                    :
                        <>
                            <button type="button" className="btn btn-secondary" onClick={() => handleProcesar(true)}><i className="fas fa-trash fa-sm"></i> Clear filters</button>
                            <button type="button" className="btn btn-success" onClick={() => handleProcesar(false)}><i className="fas fa-search fa-sm"></i> Search</button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default FiltrosProducto;