import axios from 'axios';

import { CONFIGURATION_PROFILE } from '../actions/constants';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Profile */
export const saveProfile = (profile) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuration/edit_profile_personal`, profile, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURATION_PROFILE.EDIT,
            payload: res.data.user
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

/* Company */
export const saveCompany = (company) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try {
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/configuration/edit_profile_company`, company, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CONFIGURATION_PROFILE.EDIT,
            payload: res.data.user
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error) {
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}