// Alerts
export const ALERT = {
    SET: 'SET_ALERT', 
    REMOVE: 'REMOVE_ALERT'
};

// Breadcrumb
export const BREADCRUMB = {
    EDIT: 'EDIT_BREADCRUMB'
};

// Loader
export const LOADER = {
    SHOW: 'SHOW_LOADER',
    HIDE: 'HIDE_LOADER'

};

// Auth
export const AUTH = {
    LOGIN: 'LOGIN',
    LOGIN_ERROR: 'LOGIN_ERROR',
    GET_USER: 'GET_USER',
    GET_USER_ERROR: 'GET_USER_ERROR',
    LOGOUT: 'LOGOUT'
};

// Dashboard
export const DASHBOARD = {
    GET: 'GET_DASHBOARD'
};

// Projects
export const PROJECT = {
    FIND: 'FIND_PROJECTS', 
    DETAILS: 'DETAILS_PROJECT', 
    DELETE: 'DELETE_PROJECT'
};

// Users
export const USER = {
    FIND: 'FIND_USERS', 
    DETAILS: 'DETAILS_USER', 
    DELETE: 'DELETE_USER'
};

// Types
export const TYPE = {
    FIND: 'FIND_TYPES', 
    DETAILS: 'DETAILS_TYPE', 
    DELETE: 'DELETE_TYPE'
};

// Expenses
export const EXPENSE = {
    FIND: 'FIND_EXPENSES', 
    DETAILS: 'DETAILS_EXPENSE', 
    DELETE: 'DELETE_EXPENSE'
};

// Category
export const CATEGORY = {
    FIND: 'FIND_CATEGORIES', 
    DETAILS: 'DETAILS_CATEGORY', 
    DELETE: 'DELETE_CATEGORY'
};

// Tasks
export const TASK = {
    FILTERS: 'FILTER_TASKS', 
    FIND: 'FIND_TASKS', 
    ADD: 'ADD_TASK', 
    DETAILS: 'DETAILS_TASK', 
    STATUS: 'STATUS_TASK', 
    EDIT: 'EDIT_TASK', 
    DELETE: 'DELETE_TASK'
};

// Task type
export const TASK_TYPE = {
    FIND: 'FIND_TASKS_TYPES', 
    ADD: 'ADD_TASK_TYPE', 
    DETAILS: 'DETAILS_TASK_TYPE', 
    EDIT: 'EDIT_TASK_TYPE', 
    DELETE: 'DELETE_TASK_TYPE'
};

// Task report
export const TASK_REPORT = {
    FILTERS: 'FILTER_TASKS_REPORTS', 
    FIND: 'FIND_TASKS_REPORTS'
};

// Configuration
export const CONFIGURATION_PROFILE = {
    EDIT: 'EDIT_PROFILE'
};