import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDropzone } from 'react-dropzone';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { findProjects } from '../../actions/projects';
import { findCategories } from '../../actions/categories';
import { editExpense, getExpense, clearExpense } from '../../actions/expenses';

import { CustomValueContainer } from '../../components/react_select/ReactSelect';

const EditExpense = () => {

    let breadcrumbRoutes = [
        {
            activo: false,
            name: 'Expenses',
            path: '/expenses'
        }, 
        {
            activo: true,
            name: 'Edit expense',
            path: '/expenses/edit/'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();
    
    const { id } = useParams();
    
    const { details } = useSelector(state => state.expenses);
    const { list: list_projects } = useSelector(state => state.projects);
    const { list: list_categories } = useSelector(state => state.categories);
    
    const [expenseData, setExpenseData] = useState({
        id: null, 
        project: null, 
        category: null, 
        amount: null, 
        created: null, 
        description: '', 
        files_saved: [], 
        files_delete: []
    });

    const [projectData, setProjectData] = useState(null);
    const [projectsFilter, setProjectsFilter] = useState([]);

    const [categoryData, setCategoryData] = useState(null);
    const [categoriesFilter, setCategoriesFilter] = useState([]);

    const [files, setFiles] = useState([]);
    const [extensions, setExtensions] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided, 
        }),
        control: (provided, state) => ({
            ...provided,
            paddingTop: '5px',
            paddingBottom: '5px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Edit user */
    const handleSelectProject = (option) => {
        setProjectData(option);

        setExpenseData({
            ...expenseData,
            project: option != null ? option.value : null
        });
    }

    const handleSelectCategory = (option) => {
        setCategoryData(option);

        setExpenseData({
            ...expenseData,
            category: option != null ? option.value : null
        });
    }

    const handleNumberChange = (name, value) => {
        setExpenseData({
            ...expenseData,
            [name]: value || ''
        });
    }

    const handleDatePicker = (name, value) => {
        setExpenseData({
            ...expenseData,
            [name]: value
        });
    }

    const handleInputChange = ({ target }) => {
        setExpenseData({
            ...expenseData,
            [target.name]: target.value
        });
    }

    const handleOnDrop = (acceptedFiles) => {
        let acceptedExtensions = [];

        for (let i = 0; i < acceptedFiles?.length; i++) {
            let name_splitted = acceptedFiles[i]?.name?.split('.');
            acceptedExtensions.push(name_splitted[name_splitted.length - 1].toLowerCase());
        }

        setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
        setExtensions((prevExtensions) => [...prevExtensions, ...acceptedExtensions]);
    }

    const handleViewFile = (index) => {
        if(index !== null){
            const url = URL.createObjectURL(index);
            window.open(url);
        }else{
            dispatch(setAlert('No file selected', 'danger'));
        }
    }

    const handleRemoveFile = (fileToRemove) => {
        setFiles((prevFiles) => prevFiles.filter(file => file !== fileToRemove));
    }

    const handleViewFileSaved = (url) => {
        if(url){
            window.open(url);
        }else{
            dispatch(setAlert('No file selected', 'danger'));
        }
    }

    const handleRemoveFileSaved = (key) => {
        let files_saved = expenseData.files_saved;
        let files_delete = expenseData.files_delete;
        let file_remove = files_saved.find(file => file.key == key);

        files_delete.push(file_remove);
        files_saved = files_saved.filter(file => file.key != key);

        setExpenseData({
            ...expenseData, 
            files_saved, 
            files_delete
        });
    }

    const handleSave = async () => {
        if(expenseData.amount){
            let formData = new FormData();

            formData.append('id', expenseData.id);
            formData.append('project', expenseData.project);
            formData.append('category', expenseData.category);
            formData.append('amount', expenseData.amount);
            formData.append('created', expenseData.created);
            formData.append('description', expenseData.description);
            formData.append('files_delete', JSON.stringify(expenseData.files_delete));

            for (var i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
                formData.append('extensions', extensions[i]);
            }

            await dispatch(editExpense(formData));

            await navigate('/expenses');
        }else{
            await dispatch(setAlert('Amount is required', 'danger'));
        }
    }

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleOnDrop,
        multiple: true
    });

    useEffect(() => {
        dispatch(findProjects());
        dispatch(findCategories());
        dispatch(clearExpense());

        return () => {
            dispatch(clearExpense());
        }
    }, []);

    useEffect(() => {
        if(id){
            dispatch(getExpense(id));
            
            breadcrumbRoutes[1].path = breadcrumbRoutes[1].path + id;
            
            dispatch(editBreadcrumb(breadcrumbRoutes));
        }
    }, [id]);

    useEffect(() => {
        if(details){
            setExpenseData({
                ...expenseData,
                id: details._id,
                project: details.project, 
                category: details.category,
                amount: details.amount, 
                created: details.created, 
                description: details.description, 
                files_saved: details.files_saved
            });
        }
    }, [details]);

    useEffect(() => {
        if(details){
            if(projectsFilter.length > 0){
                let project_assigned = projectsFilter.find( project => String(project.value) === String(details.project) );
    
                if(project_assigned){
                    setProjectData({value: project_assigned.value, label: project_assigned.label});
                }
            }
        }
    }, [projectsFilter, details]); 

    useEffect(() => {
        if(details){
            if(categoriesFilter.length > 0){
                let category_assigned = categoriesFilter.find( category => String(category.value) === String(details.category) );
    
                if(category_assigned){
                    setCategoryData({value: category_assigned.value, label: category_assigned.label});
                }
            }
        }
    }, [categoriesFilter, details]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < list_projects?.length; i++) {
            options.push({ value: list_projects[i]?._id, label: list_projects[i]?.address });
        }

        setProjectsFilter(options);
    }, [list_projects]);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < list_categories?.length; i++) {
            options.push({ value: list_categories[i]?._id, label: list_categories[i]?.name });
        }

        setCategoriesFilter(options);
    }, [list_categories]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 py-4">
                <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    id="project"
                                    name="project"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Project"
                                    options={projectsFilter}
                                    value={projectData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectProject(value)}
                                    menuPortalTarget={document.body}
                                    components={{ ValueContainer: CustomValueContainer }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    id="category"
                                    name="category"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Category"
                                    options={categoriesFilter}
                                    value={categoryData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectCategory(value)}
                                    menuPortalTarget={document.body}
                                    components={{ ValueContainer: CustomValueContainer }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <CurrencyInput
                                        id="amount"
                                        name="amount"
                                        className="form-control text-center"
                                        value={expenseData.amount || ''}
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value, name) => handleNumberChange(name, value)}
                                    />
                                    <label htmlFor="amount">Amount</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <DatePicker
                                        id="created"
                                        name="created"
                                        className="form-control text-center input-datepícker"
                                        dateFormat="dd/MM/yyyy"
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        selected={ expenseData.created != null ? new Date(expenseData.created) : '' }
                                        onChange={ date => handleDatePicker('created', date) }
                                    />
                                    <label className="label-datepicker" htmlFor="created">Date</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <textarea className="form-control textareCampo" placeholder="Description" id="description" name="description" rows={2} value={expenseData.description} onChange={e => handleInputChange(e)}></textarea>
                                    <label htmlFor="description">Description</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 form-group mb-3">
                            <div {...getRootProps()} className="dropzone border pt-2 mb-2 text-center bg-light">
                                <input {...getInputProps()} />
                                <div className="text-muted">
                                    <h3><i className="bi bi-cloud-arrow-up mb-2"></i></h3>
                                    <h5>Drag & Drop your files here</h5>
                                    <p>or click to select files</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 row mx-0 mb-3">
                            {
                                expenseData.files_saved.map((file, index) => (
                                    <div className="col-md-6" key={index}>
                                        <div className="dropfile border px-3 py-2 my-1 mx-1" key={index}>
                                            {file.name}
                                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleRemoveFileSaved(file.key)}><i className="bi bi-x-lg text-danger"></i></button>
                                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleViewFileSaved(file.url)}><i className="bi bi-eye-fill text-success"></i></button>
                                        </div>
                                    </div>
                                ))
                            }

                            {
                                files.map((file, index) => (
                                    <div className="col-md-6" key={index}>
                                        <div className="dropfile border px-3 py-2 my-1 mx-1" key={index}>
                                            {file.name}
                                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleRemoveFile(file)}><i className="bi bi-x-lg text-danger"></i></button>
                                            <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleViewFile(file)}><i className="bi bi-eye-fill text-success"></i></button>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleSave}>Save</button>
                            <Link to="/expenses"><button className="btn btn-danger float-end me-2">Cancel</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EditExpense;