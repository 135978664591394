import { TASK_REPORT } from "../actions/constants";

const initialState = {
    filter_window: false, 
    list: [],
    details: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case TASK_REPORT.FILTERS:
            return {
                ...state,
                filter_window: payload 
            };

        case TASK_REPORT.FIND:
            return {
                ...state,
                list: payload.list,
                details: payload.details
            };

        default:
            return state;
    }
}