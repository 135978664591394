import axios from 'axios';

import { CATEGORY } from './constants';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* Categories */
export const findCategories = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/categories/view`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CATEGORY.FIND,
            payload: res.data.categories
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const addCategory = (category) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/categories/add`, category, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const getCategory = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/categories/get`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CATEGORY.DETAILS,
            payload: res.data.category
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const clearCategory = () => dispatch => {
    dispatch({
        type: CATEGORY.DETAILS,
        payload: null
    });
}

export const editCategory = (category) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/categories/edit`, category, config);
        await dispatch(changeLoader(false));

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const deleteCategory = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/categories/delete`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: CATEGORY.DELETE,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}