import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Select from 'react-select';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { editProject, getProject, clearProject } from '../../actions/projects';

import { CustomValueContainer } from '../../components/react_select/ReactSelect';

const EditProject = () => {

    let breadcrumbRoutes = [
        {
            activo: false,
            name: 'Projects',
            path: '/projects'
        }, 
        {
            activo: true,
            name: 'Edit project',
            path: '/projects/edit/'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();
    
    const { id } = useParams();
    
    const { details } = useSelector(state => state.projects);
    
    const [projectData, setProjectData] = useState({
        id: null, 
        address: null, 
        apn: null, 
        project_type: null,
        property_type: null
    });

    const [projectTypeData, setProjectTypeData] = useState(null);
    const [propertyTypeData, setPropertyTypeData] = useState(null);

    const projectTypesFilter = [
        {
            label: 'Purchase',
            options: [
                { value: 0, label: 'Assigned' },
                { value: 1, label: 'Flipping' },
                { value: 2, label: 'Rent to hold' },
                { value: 3, label: 'New development' },
                { value: 4, label: 'To do entitlement' }
            ]
        },
        {
            label: 'Construction',
            options: [
                { value: 5, label: 'New' },
                { value: 6, label: 'Rehab' },
                { value: 7, label: 'Entitlement' },
                { value: 8, label: 'Other' }
            ]
        }
    ];

    const propertyTypesFilter = [
        { value: 0, label: 'Single family (1)' },
        { value: 1, label: 'Single family (2-4)' },
        { value: 2, label: 'Multiple family' },
        { value: 3, label: 'Mix use' },
        { value: 4, label: 'Studing housing' },
        { value: 5, label: 'Apartment units' },
        { value: 6, label: 'Condominuims' },
        { value: 7, label: 'Vacant land' }
    ];

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided, 
        }),
        control: (provided, state) => ({
            ...provided,
            paddingTop: '5px',
            paddingBottom: '5px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Edit user */
    const handleInputChange = ({ target }) => {
        setProjectData({
            ...projectData,
            [target.name]: target.value
        });
    }

    const handleSelectProjectType = (option) => {
        setProjectTypeData(option);

        setProjectData({
            ...projectData,
            project_type: option != null ? option.value : null
        });
    }

    const handleSelectPropertyType = (option) => {
        setPropertyTypeData(option);

        setProjectData({
            ...projectData,
            property_type: option != null ? option.value : null
        });
    }

    const handleSave = async () => {
        if(projectData.address && projectData.project_type != null && projectData.property_type != null){
            await dispatch(editProject(projectData));

            await navigate('/projects');
        }else{
            await dispatch(setAlert('All fields are required', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(clearProject());

        return () => {
            dispatch(clearProject());
        }
    }, []);

    useEffect(() => {
        if(id){
            dispatch(getProject(id));
            
            breadcrumbRoutes[1].path = breadcrumbRoutes[1].path + id;
            
            dispatch(editBreadcrumb(breadcrumbRoutes));
        }
    }, [id]);

    useEffect(() => {
        if(details){
            setProjectData({
                ...projectData,
                id: details._id,
                address: details.address, 
                apn: details.apn, 
                project_type: details.project_type,
                property_type: details.property_type
            });

            if (projectTypesFilter.length > 0) {
                let project_type_assigned = null;
                
                projectTypesFilter.forEach(typeGroup => {
                    const found = typeGroup.options.find(option => String(option.value) === String(details.project_type));
                    if (found) {
                        project_type_assigned = found;
                    }
                });
            
                if (project_type_assigned) {
                    setProjectTypeData({ value: project_type_assigned.value, label: project_type_assigned.label });
                }
            }

            if(propertyTypesFilter.length > 0){
                let property_type_assigned = propertyTypesFilter.find( type => String(type.value) === String(details.property_type) );
    
                if(property_type_assigned){
                    setPropertyTypeData({value: property_type_assigned.value, label: property_type_assigned.label});
                }
            }
        }
    }, [details]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 py-4">
                <div className="row">
                        <div className="col-md-9 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Address" id="address" name="address" value={projectData.address} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="address">Address</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="APN" id="apn" name="apn" value={projectData.apn} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="apn">APN</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    id="type"
                                    name="type"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Project type"
                                    options={projectTypesFilter}
                                    value={projectTypeData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectProjectType(value)}
                                    menuPortalTarget={document.body}
                                    components={{ ValueContainer: CustomValueContainer }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    id="type"
                                    name="type"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Property type"
                                    options={propertyTypesFilter}
                                    value={propertyTypeData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectPropertyType(value)}
                                    menuPortalTarget={document.body}
                                    components={{ ValueContainer: CustomValueContainer }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleSave}>Save</button>
                            <Link to="/projects"><button className="btn btn-danger float-end me-2">Cancel</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EditProject;