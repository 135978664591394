import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { addUser } from '../../actions/users';
import { findTypes } from '../../actions/types';

import { CustomValueContainer } from '../../components/react_select/ReactSelect';

const AddUser = () => {

    let breadcrumbRoutes = [
        {
            activo: false,
            name: 'Employees',
            path: '/employees'
        }, 
        {
            activo: true,
            name: 'Add employee',
            path: '/employees/add'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { list } = useSelector(state => state.types);

    const [userData, setUserData] = useState({
        name: null, 
        lastname: null, 
        type: null,
        email: null,
        username: null,
        password: null,
        salary: 0
    });

    const [typeData, setTypeData] = useState(null);
    const [typesFilter, setTypesFilter] = useState([]);

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided, 
        }),
        control: (provided, state) => ({
            ...provided,
            paddingTop: '5px',
            paddingBottom: '5px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Add user */
    const handleInputChange = ({ target }) => {
        setUserData({
            ...userData,
            [target.name]: target.value
        });
    }

    const handleSelectType = (option) => {
        setTypeData(option);

        setUserData({
            ...userData,
            type: option != null ? option.value : null
        });
    }

    const handleNumberChange = (name, value) => {
        setUserData({
            ...userData,
            [name]: value || ''
        });
    }

    const handleSave = async () => {
        if(userData.name && userData.username && userData.password){
            await dispatch(addUser(userData));

            await navigate('/employees');
        }else{
            await dispatch(setAlert('All fields are required', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(findTypes());
    }, []);

    useEffect(() => {
        let options = [];

        for (var i = 0; i < list?.length; i++) {
            options.push({ value: list[i]?._id, label: list[i]?.name });
        }

        setTypesFilter(options);
    }, [list]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 py-4">
                    <div className="row">
                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Name" id="name" name="name" value={userData.name} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="name">Name</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Lastname" id="lastname" name="lastname" value={userData.lastname} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="lastname">Lastname</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group h-100">
                                <Select
                                    id="type"
                                    name="type"
                                    className="select-group"
                                    classNamePrefix="react-select"
                                    placeholder="Type"
                                    options={typesFilter}
                                    value={typeData}
                                    isClearable={true}
                                    onChange={(value) => handleSelectType(value)}
                                    menuPortalTarget={document.body}
                                    components={{ ValueContainer: CustomValueContainer }}
                                    styles={{ ...styles_select }}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Email" id="email" name="email" value={userData.email} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="email">Email</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Username" id="username" name="username" value={userData.username} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="username">Username</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <input type="text" className="form-control" placeholder="Password" id="password" name="password" value={userData.password} onChange={e => handleInputChange(e)}/>
                                    <label htmlFor="password">Password</label>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3 form-group mb-3">
                            <div className="input-group">
                                <div className="form-floating">
                                    <CurrencyInput
                                        id="salary"
                                        name="salary"
                                        className="form-control text-center"
                                        value={userData.salary || ''}
                                        decimalsLimit={2}
                                        groupSeparator=","
                                        decimalSeparator="."
                                        onValueChange={(value, name) => handleNumberChange(name, value)}
                                    />
                                    <label htmlFor="salary">Salary per hour</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleSave}>Save</button>
                            <Link to="/employees"><button className="btn btn-danger float-end me-2">Cancel</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AddUser;