import { combineReducers } from 'redux';

import alert from './alert';
import loader from './loader';
import breadcrumb from './breadcrumb';

import auth from './auth';
import dashboard from './dashboard';
import projects from './projects';
import users from './users';
import types from './types';
import expenses from './expenses';
import categories from './categories';
import tasks from './tasks';
import tasks_types from './tasks_types';
import tasks_reports from './tasks_reports';

export default combineReducers({
    alert, loader, breadcrumb, 
    auth, dashboard, projects, users, types, expenses, categories, tasks, tasks_types, tasks_reports
});