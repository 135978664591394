import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Colorful } from '@uiw/react-color';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { addCategory } from '../../actions/categories';

const AddCategory = () => {

    let breadcrumbRoutes = [
        {
            activo: false,
            name: 'Categories',
            path: '/categories'
        }, 
        {
            activo: true,
            name: 'Add category',
            path: '/categories/add'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const [categoryData, setCategoryData] = useState({
        name: null, 
        description: null, 
        color: '#fff'
    });

    /* Add category */
    const handleInputChange = ({ target }) => {
        setCategoryData({
            ...categoryData,
            [target.name]: target.value
        });
    }

    const handleSelectColor = (color) => {
        setCategoryData({
            ...categoryData,
            color: color.hex
        });
    }

    const handleSave = async () => {
        if(categoryData.name){
            await dispatch(addCategory(categoryData));

            await navigate('/categories');
        }else{
            await dispatch(setAlert('Name is required', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 py-4">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="col-md-12 form-group mb-3">
                                <div className="input-group">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" placeholder="Name" id="name" name="name" value={categoryData.name} onChange={e => handleInputChange(e)}/>
                                        <label htmlFor="name">Name</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 form-group mb-3">
                                <div className="input-group">
                                    <div className="form-floating">
                                        <textarea className="form-control textareCampo" placeholder="Description" id="description" name="description" rows={3} value={categoryData.description} onChange={e => handleInputChange(e)}></textarea>
                                        <label htmlFor="description">Description</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="col-md-12 form-group mb-3">
                                <div className="color-group">
                                    <label className="color-label" htmlFor="color">Color</label>
                                    <div className="color-input">
                                        <Colorful 
                                            style={{ width: '100%' }}
                                            color={categoryData.color}
                                            onChange={(color) => handleSelectColor(color)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleSave}>Save</button>
                            <Link to="/categories"><button className="btn btn-danger float-end me-2">Cancel</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default AddCategory;