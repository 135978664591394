import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { Colorful } from '@uiw/react-color';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { setAlert } from '../../actions/alert';
import { editCategory, getCategory, clearCategory } from '../../actions/categories';

const EditCategory = () => {

    let breadcrumbRoutes = [
        {
            activo: false,
            name: 'Categories',
            path: '/categories'
        }, 
        {
            activo: true,
            name: 'Edit category',
            path: '/categories/edit/'
        }
    ];

    const dispatch = useDispatch();

    const navigate = useNavigate();
    
    const { id } = useParams();
    
    const { details } = useSelector(state => state.categories);
    
    const [categoryData, setCategoryData] = useState({
        id: null, 
        name: null, 
        description: null, 
        color: '#fff'
    });

    /* Edit user */
    const handleInputChange = ({ target }) => {
        setCategoryData({
            ...categoryData,
            [target.name]: target.value
        });
    }

    const handleSelectColor = (color) => {
        setCategoryData({
            ...categoryData,
            color: color.hex
        });
    }

    const handleSave = async () => {
        if(categoryData.name){
            await dispatch(editCategory(categoryData));

            await navigate('/categories');
        }else{
            await dispatch(setAlert('Name is required', 'danger'));
        }
    }

    useEffect(() => {
        dispatch(clearCategory());

        return () => {
            dispatch(clearCategory());
        }
    }, []);

    useEffect(() => {
        if(id){
            dispatch(getCategory(id));
            
            breadcrumbRoutes[1].path = breadcrumbRoutes[1].path + id;
            
            dispatch(editBreadcrumb(breadcrumbRoutes));
        }
    }, [id]);

    useEffect(() => {
        if(details){
            setCategoryData({
                ...categoryData,
                id: details._id,
                name: details.name, 
                description: details.description, 
                color: details.color
            });
        }
    }, [details]);

    return (
        <Fragment>
            <div className="row">
                <div className="col-md-12 py-4">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="col-md-12 form-group mb-3">
                                <div className="input-group">
                                    <div className="form-floating">
                                        <input type="text" className="form-control" placeholder="Name" id="name" name="name" value={categoryData.name} onChange={e => handleInputChange(e)}/>
                                        <label htmlFor="name">Name</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 form-group mb-3">
                                <div className="input-group">
                                    <div className="form-floating">
                                        <textarea className="form-control textareCampo" placeholder="Description" id="description" name="description" rows={3} value={categoryData.description} onChange={e => handleInputChange(e)}></textarea>
                                        <label htmlFor="description">Description</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="col-md-12 form-group mb-3">
                                <div className="color-group">
                                    <label className="color-label" htmlFor="color">Color</label>
                                    <div className="color-input">
                                        <Colorful 
                                            style={{ width: '100%' }}
                                            color={categoryData.color}
                                            onChange={(color) => handleSelectColor(color)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <button className="btn btn-success float-end" onClick={handleSave}>Save</button>
                            <Link to="/categories"><button className="btn btn-danger float-end me-2">Cancel</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default EditCategory;