import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatNumber, getTextColor } from '../../utils/generalFunctions';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { findCategories } from '../../actions/categories';

import DeleteCategory from './DeleteCategory';

const Categories = () => {

    let breadcrumbRoutes = [
        {
            activo: true,
            name: 'Categories',
            path: '/categories'
        }
    ];

    const dispatch =  useDispatch();

    const { list } = useSelector(state => state.categories);

    const [categories, setCategories] = useState([]);

    const [categoryDelete, setCategoryDelete] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const columns = [
        {
            name: 'Name',
            sortable: true, 
            selector: row => row.name, 
            grow: 2
        },
        {
            name: 'Color',
            cell: row => (
                <div className="text-right w-100">
                    <h5>
                        <span className="badge" style={{ backgroundColor: row.color, color: getTextColor(row.color) }}>
                            { row.color }
                        </span>
                    </h5>
                </div>
            )
        },
        {
            name: 'Expenses',
            sortable: true, 
            selector: row => formatNumber(row.expenses_total, 0, '', '')
        },
        {
            name: 'Expense amount',
            sortable: true, 
            selector: row => formatNumber(row.expenses_amount, 2, '$ ', '')
        },
        {
            name: '',
            cell: row => (
                <div className="text-right w-100">
                    <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDelete(row)}><i className="bi bi-x-lg text-danger"></i></button>
                    <Link className="btn btn-sm btn-link px-2 float-end" to={`/categories/edit/${row._id}`}><i className="bi bi-pencil-fill text-primary"></i></Link>
                </div>
            )
        }
    ];

    /* Delete */
    const handleDelete = (category) => {
        setCategoryDelete(category);
    }

    useEffect(() => {
        dispatch(findCategories());
    }, []);

    useEffect(() => {
        setCategories(list);
    }, [list]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>

            <DeleteCategory category={categoryDelete} restablishCategory={handleDelete} />

            <div className="row">
                <div className="col-md-12 py-4">

                    <div className="d-flex justify-content-end">
                        <Link to="/categories/add">
                            <button className="btn btn-primary">Add category</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={categories}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Categories;