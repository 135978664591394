import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatNumber } from '../../utils/generalFunctions';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { findProjects } from '../../actions/projects';

import DeleteProject from './DeleteProject';

const Projects = () => {

    let breadcrumbRoutes = [
        {
            activo: true,
            name: 'Projects',
            path: '/projects'
        }
    ];

    const dispatch =  useDispatch();

    const { list } = useSelector(state => state.projects);

    const [projects, setProjects] = useState([]);

    const [projectDelete, setProjectDelete] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const columns = [
        {
            name: 'Address',
            sortable: true, 
            selector: row => row.address, 
            grow: 3
        },
        {
            name: 'APN',
            sortable: true, 
            selector: row => row.apn
        },
        {
            name: 'Project type',
            sortable: true, 
            selector: row => row.project_type?.name || 'Not defined'
        },
        {
            name: 'Property type',
            sortable: true, 
            selector: row => row.property_type?.name || 'Not defined'
        },
        {
            name: 'Expense amount',
            sortable: true, 
            selector: row => formatNumber(row.expenses_amount, 2, '$ ', '')
        },
        {
            name: '',
            cell: row => (
                <div className="text-right w-100">
                    <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDelete(row)}><i className="bi bi-x-lg text-danger"></i></button>
                    <Link className="btn btn-sm btn-link px-2 float-end" to={`/projects/edit/${row._id}`}><i className="bi bi-pencil-fill text-primary"></i></Link>
                </div>
            )
        }
    ];

    /* Delete */
    const handleDelete = (project) => {
        setProjectDelete(project);
    }

    useEffect(() => {
        dispatch(findProjects());
    }, []);

    useEffect(() => {
        setProjects(list);
    }, [list]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>

            <DeleteProject project={projectDelete} restablishProject={handleDelete} />

            <div className="row">
                <div className="col-md-12 py-4">

                    <div className="d-flex justify-content-end">
                        <Link to="/projects/add">
                            <button className="btn btn-primary">Add project</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={projects}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Projects;