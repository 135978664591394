import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { formatNumber, sortTableDate } from '../../utils/generalFunctions';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { findExpenses } from '../../actions/expenses';

import DeleteExpense from './DeleteExpense';

const Expenses = () => {

    let breadcrumbRoutes = [
        {
            activo: true,
            name: 'Expenses',
            path: '/expenses'
        }
    ];

    const dispatch =  useDispatch();

    const { list } = useSelector(state => state.expenses);

    const [expenses, setExpenses] = useState([]);

    const [expenseDelete, setExpenseDelete] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const columns = [
        {
            name: 'Description',
            sortable: true,
            cell: item => `${item.description || 'Not defined'}`,
            wrap: false,
            grow: 2
        },
        {
            name: 'Project',
            sortable: true,
            cell: item => `${item.project?.address || 'Not defined'}`,
            wrap: false,
            grow: 2
        },
        {
            name: 'Category',
            sortable: true, 
            selector: row => row.category?.name || 'Not defined'
        },
        {
            name: 'User',
            sortable: true, 
            selector: row => row.user ? [row.user.name, row.user.lastname].join(' ') : 'Not defined'
        },
        {
            name: 'Amount',
            sortable: true, 
            selector: row => formatNumber(row.amount, 2, '$ ', '')
        },
        {
            name: 'Created', 
            sortable: true, 
            sortFunction: sortTableDate, 
            cell: item => item.created ? new Date(item.created).toLocaleDateString('en-EU', { year: 'numeric', month: 'long', day: 'numeric' }) : 'Not defined'
        },
        {
            name: '',
            cell: row => (
                <div className="text-right w-100">
                    <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDelete(row)}><i className="bi bi-x-lg text-danger"></i></button>
                    <Link className="btn btn-sm btn-link px-2 float-end" to={`/expenses/edit/${row._id}`}><i className="bi bi-pencil-fill text-primary"></i></Link>
                </div>
            )
        }
    ];

    /* Delete */
    const handleDelete = (expense) => {
        setExpenseDelete(expense);
    }

    useEffect(() => {
        dispatch(findExpenses());
    }, []);

    useEffect(() => {
        setExpenses(list);
    }, [list]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>

            <DeleteExpense expense={expenseDelete} restablishExpense={handleDelete} />

            <div className="row">
                <div className="col-md-12 py-4">

                    <div className="d-flex justify-content-end">
                        <Link to="/expenses/add">
                            <button className="btn btn-primary">Add expense</button>
                        </Link>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={expenses}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Expenses;