import React, { useEffect, useState, createRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { editBreadcrumb } from '../../../actions/breadcrumb';
import { saveProfile, saveCompany } from '../../../actions/configuration';

import { CustomValueContainer } from '../../../components/react_select/ReactSelect';

const Perfil = () => {

    let rutasBread = [
        {
            activo: true,
            name: 'Configuration',
            path: '/configuration'
        }
    ];

    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    
    /* Profile */
    const [profileData, setProfileData] = useState({
        name: '',
        lastname: '',
        email: '',
        username: '',
        password: '',
        image: null
    });

    const [profilePicture, setProfilePicture] = useState({
        img: undefined,
    });

    let img_profile = createRef();

    /* Company */
    const [companyData, setCompanyData] = useState({
        name: '',
        phone: '',
        email: '',
        address: '',
        currency: '',
        work_hours: 0,
        image: null
    });

    const [currencyData, setCurrencyData] = useState(null);
    const currenciesFilter = [
        {
            value: 0,
            label: 'United States Dollar (USD)'
        }, 
        {
            value: 1,
            label: 'Euro (EUR)'
        }, 
        {
            value: 2,
            label: 'Mexican Peso (MXN)'
        }, 
        {
            value: 3,
            label: 'Canadian Dollar (CAD)'
        }, 
        {
            value: 4,
            label: 'Guatemalan Quetzal (GTQ)'
        }
    ];

    const [companyPicture, setCompanyPicture] = useState({
        img: undefined,
    });

    let img_company = createRef();

    let styles_select = {
        menuPortal: base => ({ ...base, zIndex: 9999 }), 
        container: (provided, state) => ({
            ...provided, 
        }),
        control: (provided, state) => ({
            ...provided,
            paddingTop: '5px',
            paddingBottom: '5px',
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            overflow: "visible", 
            marginTop: "10px"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            position: "absolute",
            top: state.hasValue || state.selectProps.inputValue ? -15 : "0%",
            transition: "top 0.1s, font-size 0.1s",
            fontSize: (state.hasValue || state.selectProps.inputValue) && 13
        })
    };

    /* Guardar perfil */
    const onChangeImgProfile = () => {
        setProfilePicture({
            ...profilePicture, img: img_profile.current.files[0]
        });
    }

    const onChangeImgCompany = () => {
        setCompanyPicture({
            ...companyPicture, img: img_company.current.files[0]
        });
    }

    const onChangeProfile = (e) => {
        setProfileData({
            ...profileData, [e.target.name]: e.target.value
        });
    }

    const onChangeCompany = (e) => {
        setCompanyData({
            ...companyData, [e.target.name]: e.target.value
        });
    }

    const handleSelectCurrency = (option) => {
        setCurrencyData(option);

        setCompanyData({
            ...companyData,
            currency: option != null ? option.value : null
        });
    }

    const handleSubmitProfile = async (e) => {
        e.preventDefault();

        try {
            let formData = new FormData();

            formData.append('name', profileData.name);
            formData.append('lastname', profileData.lastname);
            formData.append('email', profileData.email);
            formData.append('username', profileData.username);
            formData.append('password', profileData.password);
            formData.append('image', profilePicture.img);

            dispatch(saveProfile(formData));
        } catch (error) {
            return false;
        }
    }

    const handleSubmitCompany = async (e) => {
        e.preventDefault();

        try {
            let formData = new FormData();

            formData.append('name', companyData.name);
            formData.append('phone', companyData.phone);
            formData.append('email', companyData.email);
            formData.append('address', companyData.address);
            formData.append('currency', companyData.currency);
            formData.append('work_hours', companyData.work_hours);
            formData.append('image', companyPicture.img);

            dispatch(saveCompany(formData));
        } catch (error) {
            return false;
        }
    }

    /* Funciones generales */
    const onViewPassword = () => {
        let perfilInputType = document.getElementById('password').type;
        if(perfilInputType === 'password')document.getElementById('password').type = 'text';
        else document.getElementById('password').type = 'password';
    }

    useEffect(() => {
        setProfileData({
            ...profileData,
            id: user?._id,
            name: user?.name,
            lastname: user?.lastname,
            email: user?.email,
            username: user?.username,
            image: user?.url_perfil_g ? user?.url_perfil_g : process.env.REACT_APP_PUBLIC_ROUTE + '/assets/img/avatar-2.png'
        });

        setCompanyData({
            ...companyData,
            id: user?.company?._id,
            name: user?.company?.name,
            phone: user?.company?.phone,
            email: user?.company?.email,
            address: user?.company?.address,
            currency: user?.company?.currency,
            work_hours: user?.company?.work_hours,
            image: user?.company?.url_perfil_g ? user?.company?.url_perfil_g : process.env.REACT_APP_PUBLIC_ROUTE + '/assets/img/avatar-company-2.png'
        });

        if(currenciesFilter.length > 0){
            let currency_assigned = currenciesFilter.find( currency => String(currency.value) === String(user?.company?.currency) );

            if(currency_assigned){
                setCurrencyData({value: currency_assigned.value, label: currency_assigned.label});
            }
        }
    }, [user]);    

    useEffect(() => {
        dispatch(editBreadcrumb(rutasBread));
    }, [rutasBread]);

    return(
        <Fragment>
            <HelmetProvider>
                <Helmet>
                    <style>{`
                        input[type="file"] {
                            display: none;
                        }
                        
                        .custom-file-upload {
                            border-radius: 50%;
                            display: inline-block;
                            position: relative;
                            padding: 0px;
                            cursor: pointer;
                            background: #fff;
                            margin-bottom: 25px;
                            border: 5px solid #111F62;
                        }
                        
                        .img-wrap{
                            position: relative;
                            width: 200px;
                            height: 200px;
                            overflow: hidden;
                            border-radius: 50%;
                        }

                        .img-upload:before{
                            content: "\f615";
                            font-family: "bootstrap-icons" !important;
                            font-weight: 900;
                            font-size: 90px;
                            position: absolute;
                            padding-top: 50px;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            text-align: center;
                            color: #21B4E9;
                            width: 200px;
                            height: 200px;
                            border-radius: 50%;
                            opacity: 0;
                            transition: .5s ease;
                            background-color: #fff;
                        }

                        .img-upload:hover:before{
                            opacity: 1;
                        }

                        #img-photo {
                            width: auto;
                            height: 100%;
                        }
                    `}</style>
                </Helmet>
            </HelmetProvider>
            
            <section className="section profile">
                <div className="row">
                    <div className="col-xl-4">

                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                <img src="/assets/img/avatar.png" alt="Profile" className="rounded-circle" />
                                <h2>{user?.name + ' ' + user?.lastname}</h2>
                                <h3>{user?.type?.name || 'Administrator'}</h3>
                            </div>
                        </div>

                    </div>

                    <div className="col-xl-8">

                        <div className="card">
                            <div className="card-body pt-3">

                                <ul className="nav nav-tabs nav-tabs-bordered">
                                    <li className="nav-item">
                                        <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-edit">Profile settings</button>
                                    </li>

                                    <li className="nav-item">
                                        <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-settings">Company settings</button>
                                    </li>
                                </ul>

                                <div className="tab-content pt-2">
                                    <div className="tab-pane fade show active profile-edit pt-3" id="profile-edit">
                                        <form>
                                            <div className="row mb-5">
                                                <div className="col-12 form-group text-center">
                                                    <label htmlFor="photo-upload" className="custom-file-upload fas">
                                                        <div className="img-wrap img-upload">
                                                            <img id="img-photo" htmlFor="photo-upload" src={profilePicture.img ? URL.createObjectURL(profilePicture.img) : profileData.image}/>
                                                        </div>
                                                        <input id="photo-upload" type="file" accept="image/*" ref={img_profile} onChange={onChangeImgProfile}/> 
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 form-group mb-3">
                                                    <div className="input-group">
                                                        <div className="form-floating">
                                                            <input type="text" className="form-control" placeholder="Name" id="name" name="name" autoComplete="off" value={profileData.name} onChange={e => onChangeProfile(e)}/>
                                                            <label htmlFor="name">Name</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 form-group mb-3">
                                                    <div className="input-group">
                                                        <div className="form-floating">
                                                            <input type="text" className="form-control" placeholder="Lastname" id="lastname" name="lastname" autoComplete="off" value={profileData.lastname} onChange={e => onChangeProfile(e)}/>
                                                            <label htmlFor="lastname">Lastname</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 form-group mb-3">
                                                    <div className="input-group">
                                                        <div className="form-floating">
                                                            <input type="text" className="form-control" placeholder="Email" id="email" name="email" autoComplete="off" value={profileData.email} onChange={e => onChangeProfile(e)}/>
                                                            <label htmlFor="email">Email</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 form-group mb-3">
                                                    <div className="input-group">
                                                        <div className="form-floating">
                                                            <input type="text" className="form-control" placeholder="Username" id="username" name="username" autoComplete="off" value={profileData.username} onChange={e => onChangeProfile(e)}/>
                                                            <label htmlFor="username">Username</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 form-group mb-3">
                                                    <div className="input-group">
                                                        <div className="form-floating">
                                                            <input type="password" className="form-control" placeholder="Password" id="password" name="password" autoComplete="off" value={profileData.password} onChange={e => onChangeProfile(e)}/>
                                                            <label htmlFor="password">Password</label>
                                                        </div>
                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary viewPassword" onClick={onViewPassword}><i className="bi bi-eye-fill"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12 mt-4">
                                                    <button type="button" className="btn btn-success float-end" onClick={handleSubmitProfile}>Save</button>   
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className="tab-pane fade pt-3" id="profile-settings">
                                        <form>
                                            <div className="row mb-5">
                                                <div className="col-12 form-group text-center">
                                                    <label htmlFor="photo-upload" className="custom-file-upload fas">
                                                        <div className="img-wrap img-upload">
                                                            <img id="img-photo" htmlFor="photo-upload" src={companyPicture.img ? URL.createObjectURL(companyPicture.img) : companyData.image}/>
                                                        </div>
                                                        <input id="photo-upload" type="file" accept="image/*" ref={img_company} onChange={onChangeImgCompany}/> 
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4 form-group mb-3">
                                                    <div className="input-group">
                                                        <div className="form-floating">
                                                            <input type="text" className="form-control" placeholder="Name" id="name" name="name" autoComplete="off" value={companyData.name} onChange={e => onChangeCompany(e)}/>
                                                            <label htmlFor="name">Name</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 form-group mb-3">
                                                    <div className="input-group">
                                                        <div className="form-floating">
                                                            <input type="text" className="form-control" placeholder="Phone" id="phone" name="phone" autoComplete="off" value={companyData.phone} onChange={e => onChangeCompany(e)}/>
                                                            <label htmlFor="phone">Phone</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 form-group mb-3">
                                                    <div className="input-group">
                                                        <div className="form-floating">
                                                            <input type="text" className="form-control" placeholder="Email" id="email" name="email" autoComplete="off" value={companyData.email} onChange={e => onChangeCompany(e)}/>
                                                            <label htmlFor="email">Email</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 form-group mb-3">
                                                    <div className="input-group">
                                                        <div className="form-floating">
                                                            <input type="text" className="form-control" placeholder="Address" id="address" name="address" autoComplete="off" value={companyData.address} onChange={e => onChangeCompany(e)}/>
                                                            <label htmlFor="address">Address</label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-4 form-group mb-3">
                                                    <div className="input-group h-100">
                                                        <Select
                                                            id="currency"
                                                            name="Currency"
                                                            className="select-group"
                                                            classNamePrefix="react-select"
                                                            placeholder="Currency"
                                                            options={currenciesFilter}
                                                            value={currencyData}
                                                            isClearable={true}
                                                            onChange={(value) => handleSelectCurrency(value)}
                                                            menuPortalTarget={document.body}
                                                            components={{ ValueContainer: CustomValueContainer }}
                                                            styles={{ ...styles_select }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-3 form-group mb-3">
                                                    <div className="input-group">
                                                        <div className="form-floating">
                                                            <input type="number" className="form-control" placeholder="Working daily hours" id="work_hours" name="work_hours" autoComplete="off" value={profileData.work_hours} onChange={e => onChangeCompany(e)}/>
                                                            <label htmlFor="work_hours">Daily working hours</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12 mt-4">
                                                    <button type="button" className="btn btn-success float-end" onClick={handleSubmitCompany}>Save</button>   
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default Perfil;