import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { deleteTask } from '../../actions/tasks';

const DeleteTask = ({ task, restablishTask }) => {

    const dispatch =  useDispatch();

    const [deleteModal, setDeleteModal] = useState(false);
    const [statusProcess, setStatusProcess] = useState(false);

    /* Delete task */
    const handleProcess = async () => {
        await setStatusProcess(true);
        await dispatch(deleteTask(task?._id));
        await handleClose(null);
        await setStatusProcess(false);
    }

    const handleClose = () => {
        if(!statusProcess){
            restablishTask(null);
            setDeleteModal(false);
        }
    }

    useEffect(() => {
        if(task != null){
            setDeleteModal(true);
        }
    }, [task]);

    return (
        <Modal show={deleteModal} backdrop="static" centered onHide={() => handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Delete task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Do you want to delete the task <b>{task?.type?.name}</b> of <b>{task?.user?.name + ' ' + task?.user?.lastname}</b>?</p>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-danger mr-3" onClick={() => handleClose()} disabled={ statusProcess }><i className="fas fa-times fa-sm"></i> No</button>
                {
                    statusProcess ?
                        <button type="button" className="btn btn-success" disabled><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Yes</button>
                    :
                        <button type="button" className="btn btn-success" onClick={() => handleProcess()}><i className="fas fa-check fa-sm"></i> Yes</button>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteTask;