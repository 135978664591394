import React, { Fragment, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { formatNumber, formatTime } from '../../utils/generalFunctions';

import { editBreadcrumb } from '../../actions/breadcrumb';
import { findTasksTypes } from '../../actions/tasks_types';

import AddTaskType from './AddTaskType';
import EditTaskType from './EditTaskType';
import DeleteTaskType from './DeleteTaskType';

const TasksTypes = () => {

    let breadcrumbRoutes = [
        {
            activo: true,
            name: 'Types',
            path: '/task_types'
        }
    ];

    const dispatch =  useDispatch();

    const { list } = useSelector(state => state.tasks_types);

    const [tasks_types, setTasksTypes] = useState([]);

    const [taskTypeAdd, setTaskTypeAdd] = useState(null);
    const [taskTypeEdit, setTaskTypeEdit] = useState(null);
    const [taskTypeDelete, setTaskTypeDelete] = useState(null);

    const paginationOptions = { rowsPerPageText: 'Rows per page', rangeSeparatorText: 'of' };

    const columns = [
        {
            name: 'Name',
            sortable: true, 
            selector: row => row.name, 
            grow: 2
        },
        {
            name: 'Task assigned',
            sortable: true, 
            selector: row => formatNumber(row.tasks_assigned, 0, '', '')
        },
        {
            name: 'Total time',
            sortable: true, 
            selector: row => formatTime(row.task_time)
        },
        {
            name: 'Salary amount',
            sortable: true, 
            selector: row => formatNumber(row.salary_amount, 2, '$ ', '')
        },
        {
            name: '',
            cell: row => (
                <div className="text-right w-100">
                    <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleDelete(row)}><i className="bi bi-x-lg text-danger"></i></button>
                    <button type="button" className="btn btn-sm btn-link px-2 float-end" onClick={() => handleEdit(row)}><i className="bi bi-pencil-fill text-primary"></i></button>
                </div>
            )
        }
    ];

    /* Add */
    const handleAdd = (taskType) => {
        setTaskTypeEdit(null);
        setTaskTypeDelete(null);

        setTaskTypeAdd(taskType);
    }

    /* Edit */
    const handleEdit = (taskType) => {
        setTaskTypeAdd(null);
        setTaskTypeDelete(null);

        setTaskTypeEdit(taskType);
    }

    /* Delete */
    const handleDelete = (taskType) => {
        setTaskTypeAdd(null);
        setTaskTypeEdit(null);

        setTaskTypeDelete(taskType);
    }

    useEffect(() => {
        dispatch(findTasksTypes());
    }, []);

    useEffect(() => {
        setTasksTypes(list);
    }, [list]);

    useEffect(() => {
        dispatch(editBreadcrumb(breadcrumbRoutes));
    }, [breadcrumbRoutes]);

    return (
        <Fragment>

            <AddTaskType task_type={taskTypeAdd} restablishTaskType={handleAdd} />
            <EditTaskType task_type={taskTypeEdit} restablishTaskType={handleEdit} />
            <DeleteTaskType task_type={taskTypeDelete} restablishTaskType={handleDelete} />

            <div className="row">
                <div className="col-md-12 py-4">

                    <div className="d-flex justify-content-end">
                        <button type="button" className="btn btn-primary" onClick={() => handleAdd({ })}>Add type</button>
                    </div>

                    <div className="mt-2">
                        <DataTable 
                            columns={columns}
                            data={tasks_types}
                            pagination
                            paginationPerPage={20}
                            paginationRowsPerPageOptions={[5, 10, 20, 50]}
                            paginationComponentOptions={paginationOptions}
                            highlightOnHover={true}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default TasksTypes;