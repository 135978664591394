import { TASK } from "../actions/constants";

const initialState = {
    filter_window: false, 
    list: [],
    details: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case TASK.FILTERS:
            return {
                ...state,
                filter_window: payload 
            };

        case TASK.FIND:
            return {
                ...state,
                list: payload
            };

        case TASK.ADD:
            let task_add = state.list;

            task_add = [
                ...task_add, payload
            ];

            task_add = task_add.sort(function (a, b) {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                list: task_add
            };

        case TASK.DETAILS:
            return {
                ...state,
                details: payload
            };

        case TASK.EDIT:
            let task_edit = state.list;

            task_edit = task_edit.map(task => task._id === payload._id ? payload : task);

            task_edit = task_edit.sort(function (a, b) {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            });

            return {
                ...state, 
                list: task_edit
            };

        case TASK.DELETE:
            return {
                ...state,
                list: state.list.filter( task => task._id !== payload.id )
            };

        default:
            return state;
    }
}