import { PROJECT } from "../actions/constants";

const initialState = {
    list: [],
    details: null
};

export default (state = initialState, action) => {

    const { type, payload } = action;

    switch ( type ) {
        case PROJECT.FIND:
            return {
                ...state,
                list: payload
            };

        case PROJECT.DETAILS:
            return {
                ...state,
                details: payload
            };

        case PROJECT.DELETE:
            return {
                ...state,
                list: state.list.filter( project => project._id !== payload.id )
            };

        default:
            return state;
    }
}