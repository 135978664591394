import axios from 'axios';

import { TASK_TYPE } from './constants';

import { setAlert } from './alert';
import { changeLoader } from './loader';

/* TasksTypes */
export const findTasksTypes = () => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.get(`${process.env.REACT_APP_API_ROUTE}/tasks_types/view`, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: TASK_TYPE.FIND,
            payload: res.data.types
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const addTaskType = (task_type) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/tasks_types/add`, task_type, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: TASK_TYPE.ADD,
            payload: res.data.type
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const getTaskType = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/tasks_types/get`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: TASK_TYPE.DETAILS,
            payload: res.data.type
        });
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const clearTaskType = () => dispatch => {
    dispatch({
        type: TASK_TYPE.DETAILS,
        payload: null
    });
}

export const editTaskType = (task_type) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/tasks_types/edit`, task_type, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: TASK_TYPE.EDIT,
            payload: res.data.type
        });

        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));

        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}

export const deleteTaskType = (id) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    
    const body = {
        id
    };

    try{
        await dispatch(changeLoader(true));
        const res = await axios.post(`${process.env.REACT_APP_API_ROUTE}/tasks_types/delete`, body, config);
        await dispatch(changeLoader(false));

        await dispatch({
            type: TASK_TYPE.DELETE,
            payload: body
        });
        
        await dispatch(setAlert(res.data.msg, 'success'));
    } catch (error){ 
        await dispatch(changeLoader(false));
        
        if(error?.response?.data?.msg) {
            await dispatch(setAlert(error?.response?.data?.msg, 'danger'));
        }
    }
}