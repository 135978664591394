import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

const Sidebar = () => {

    const location = useLocation();

    const { permissions } = useSelector(state => state.auth.user);

    return (
        <Fragment>
            <aside id="sidebar" className="sidebar">
				<ul className="sidebar-nav" id="sidebar-nav">
					<li className="nav-item">
						<Link className={'nav-link' + (location.pathname === '/' || location.pathname === '/home' ? '' : ' collapsed')} to="/home">
							<i className="bi bi-grid"></i>
							<span>Home</span>
						</Link>
					</li>

					{
						permissions?.projects?.view &&
							<li className="nav-item">
								<Link className={'nav-link' + (location.pathname === '/projects' || location.pathname.substr(0, 9) === '/projects' ? '' : ' collapsed')} to="/projects">
									<i className="bi bi-house-door"></i>
									<span>Projects</span>
								</Link>
							</li>
					}

					{
						permissions?.employees?.view || permissions?.types?.view ?
							<li className="nav-item">
								<a className={'nav-link' + (location.pathname === '/employees' || location.pathname.substr(0, 10) === '/employees' || location.pathname === '/types' || location.pathname.substr(0, 6) === '/types' ? '' : ' collapsed')} data-bs-target="#employees-section" data-bs-toggle="collapse" href="#">
									<i className="bi bi-people"></i><span>Employees</span><i className="bi bi-chevron-down ms-auto"></i>
								</a>
								<ul id="employees-section" className={'nav-content collapse' + (location.pathname === '/employees' || location.pathname.substr(0, 10) === '/employees' || location.pathname === '/types' || location.pathname.substr(0, 6) === '/types' ? ' show' : '')} data-bs-parent="#sidebar-nav">
									{
										permissions?.employees?.view &&
											<li>
												<Link to="/employees" className={location.pathname === '/employees' || location.pathname.substr(0, 10) === '/employees' ? 'active' : ''}>
													<i className="bi bi-circle"></i><span>List</span>
												</Link>
											</li>
									}
									
									{
										permissions?.types?.view &&
											<li>
												<Link to="/types" className={location.pathname === '/types' || location.pathname.substr(0, 6) === '/types' ? 'active' : ''}>
													<i className="bi bi-circle"></i><span>Types</span>
												</Link>
											</li>
									}
								</ul>
							</li>
						:
							null
					}
					
					{
						permissions?.payroll?.view &&
							<li className="nav-item">
								<Link className={'nav-link' + (location.pathname === '/payroll' || location.pathname.substr(0, 8) === '/payroll' ? '' : ' collapsed')} to="/payroll">
									<i className="bi bi-wallet2"></i>
									<span>Payroll</span>
								</Link>
							</li>
					}

					{
						permissions?.expenses?.view || permissions?.categories?.view ?
							<li className="nav-item">
								<a className={'nav-link' + (location.pathname === '/expenses' || location.pathname.substr(0, 9) === '/expenses' || location.pathname === '/categories' || location.pathname.substr(0, 11) === '/categories' ? '' : ' collapsed')} data-bs-target="#expenses-section" data-bs-toggle="collapse" href="#">
									<i className="bi bi-currency-dollar"></i><span>Expenses</span><i className="bi bi-chevron-down ms-auto"></i>
								</a>
								<ul id="expenses-section" className={'nav-content collapse' + (location.pathname === '/expenses' || location.pathname.substr(0, 9) === '/expenses' || location.pathname === '/categories' || location.pathname.substr(0, 11) === '/categories' ? ' show' : '')} data-bs-parent="#sidebar-nav">
									{
										permissions?.expenses?.view &&
											<li>
												<Link to="/expenses" className={location.pathname === '/expenses' || location.pathname.substr(0, 9) === '/expenses' ? 'active' : ''}>
													<i className="bi bi-circle"></i><span>List</span>
												</Link>
											</li>
									}
									
									{
										permissions?.categories?.view &&
											<li>
												<Link to="/categories" className={location.pathname === '/categories' || location.pathname.substr(0, 11) === '/categories' ? 'active' : ''}>
													<i className="bi bi-circle"></i><span>Categories</span>
												</Link>
											</li>
									}
								</ul>
							</li>
						:
							null
					}

					{
						permissions?.tasks?.view || permissions?.tasks_types?.view ?
							<li className="nav-item">
								<a className={'nav-link' + (location.pathname === '/tasks' || location.pathname.substr(0, 6) === '/tasks' || location.pathname === '/task_types' || location.pathname.substr(0, 11) === '/task_types' || location.pathname === '/task_reports' || location.pathname.substr(0, 11) === '/task_reports' ? '' : ' collapsed')} data-bs-target="#tasks-section" data-bs-toggle="collapse" href="#">
									<i className="bi bi-check-all"></i><span>Tasks</span><i className="bi bi-chevron-down ms-auto"></i>
								</a>
								<ul id="tasks-section" className={'nav-content collapse' + (location.pathname === '/tasks' || location.pathname.substr(0, 6) === '/tasks' || location.pathname === '/task_types' || location.pathname.substr(0, 11) === '/task_types' ? ' show' : '')} data-bs-parent="#sidebar-nav">
									{
										permissions?.tasks?.view &&
											<li>
												<Link to="/tasks" className={location.pathname === '/tasks' || location.pathname.substr(0, 6) === '/tasks' ? 'active' : ''}>
													<i className="bi bi-circle"></i><span>List</span>
												</Link>
											</li>
									}
									
									{
										permissions?.tasks_types?.view &&
											<li>
												<Link to="/task_types" className={location.pathname === '/task_types' || location.pathname.substr(0, 11) === '/task_types' ? 'active' : ''}>
													<i className="bi bi-circle"></i><span>Types</span>
												</Link>
											</li>
									}

									{
										permissions?.tasks_types?.view &&
											<li>
												<Link to="/task_reports" className={location.pathname === '/task_reports' || location.pathname.substr(0, 11) === '/task_reports' ? 'active' : ''}>
													<i className="bi bi-circle"></i><span>Reports</span>
												</Link>
											</li>
									}
								</ul>
							</li>
						:
							null
					}
				</ul>
			</aside>
        </Fragment>
    )
}

export default Sidebar;